import { Avatar, Nav, Sidenav } from 'rsuite'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { navigatePage } from '../../misc/utils/navigation'
import { NAVIGATION } from '../../app/constants'
import { envSelector } from '../../features/environment/environmentSlice'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useAuthContext } from '../../auth/AuthContext'
import DashboardIcon from '@rsuite/icons/Dashboard'

const sideNavStyle: React.CSSProperties = {
  color: 'white',
  position: 'fixed',
  zIndex: 1,
  height: '100vh',
}

const avatarStyle: React.CSSProperties = {
  position: 'absolute',
  bottom: 0,
  paddingLeft: 8,
  paddingBottom: 5,
  cursor: 'pointer'
}

// Check here for correct placement
// https://rsuitejs.com/components/container/
const NavBarInstance = ({ onSelect, activeKey }) => {
  const auth = useAuthContext()

  return (
    <Sidenav
      style={sideNavStyle}
      appearance={'inverse'}
      expanded={false}>
      <Sidenav.Body>
        <Nav onSelect={onSelect} activeKey={activeKey}>
          <Nav.Item eventKey={NAVIGATION.HOME.url} icon={<DashboardIcon />}>
            {NAVIGATION.HOME.name}
          </Nav.Item>
        </Nav>
        <div style={avatarStyle} onClick={() => auth.logout()}>
          <Avatar circle src="https://picsum.photos/50" style={{ border: '1px solid white' }} />
        </div>
      </Sidenav.Body>
    </Sidenav>
  )
}

const SideNav: React.FC = () => {
  const navigate = useNavigate()
  const env = useAppSelector(envSelector)
  const dispatch = useAppDispatch()

  return (
    <NavBarInstance
      activeKey={env.page}
      onSelect={(key: string) => navigatePage(dispatch, navigate, key)} />
  )
}

export default SideNav
