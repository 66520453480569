import React, { useState } from 'react'
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline'
import './Participant.css'
import Video from '../video/Video'
import { isMobile } from 'react-device-detect'

export interface ParticipantProps {
  src: MediaStream
  muted?: boolean
  username?: string
  canCollapse?: boolean
  collapsed?: boolean
  width?: number
  height?: number
  videoWidth?: number | string
  videoHeight?: number | string
}

const Participant: React.FC<ParticipantProps> = ({ src, muted, username, canCollapse, collapsed, height, width, videoHeight, videoWidth }) => {

  let [collapsedInternal, setCollapsedInternal] = useState(collapsed ?? false)

  let nameTag = canCollapse ?
    (<span className="name-tag">
      {username}
      <span style={{ paddingLeft: 5, cursor: 'pointer' }}
            onClick={() => setCollapsedInternal(true)}>
        <CollaspedOutlineIcon/>
      </span>
    </span>) : (username && <span className="name-tag">{username}</span>)

  return collapsedInternal ?
    (
      <div className="participant participant-circle" style={{ height: height ?? 150, width: width ?? 150 }}
           onClick={() => setCollapsedInternal(isMobile)}>
        <Video src={src} muted={muted ?? false} circle width={videoWidth} height={videoHeight}/>
      </div>
    ) :
    (
      <div className="participant">
        {nameTag}
        <Video src={src} muted={muted ?? false} circle={false} width={videoWidth} height={videoHeight}/>
      </div>
    )
}

export default Participant
