import { Col, Grid, Row } from 'rsuite'
import Stats from '../../home/user/Stats'
import React from 'react'
import Pulse from '../pulse/Pulse'
import { useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user/userSlice'
import BeforeCallMain from './BeforeCallMain'

const BeforeCallUser: React.FC = () => {
  const { roomMetrics, roomCreated } = useAppSelector(userSelector)
  const line = (roomCreated as any)?.line

  return <>
    <div style={{ color: 'white', position: 'absolute', right: 55, top: 55, zIndex: 100000000 }}>
      <span style={{ position: 'absolute', right: 75, width: 110, top: 25 }}>σειρά αναμονής:</span>
      <Pulse waiting={line ?? 0}/>
    </div>

    <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: '#1F3539', zIndex: 100000 }}>
      <Grid fluid>
        <Row>
          <Col xs={4} sm={4} md={4} style={{ backgroundColor: 'white' }}>
            <Stats interpreters={roomMetrics.connected_interpreters} pending={roomMetrics.users_waiting}/>
          </Col>
          <Col xs={17} sm={17} md={17} style={{ backgroundColor: '#1F3539' }}>
            <BeforeCallMain/>
          </Col>
        </Row>
      </Grid>
    </div>
  </>
}

export default BeforeCallUser
