import { useEffect, useState } from 'react'
import { useAppSelector } from '../app/hooks'
import { envSelector } from '../features/environment/environmentSlice'
import { useWebSocketContext } from './WebsocketContext'
import { MessageType, WsMessage } from '../misc/domain/ws'

export const useUserSubscriptions = () => {
  const { connected, sendMessage } = useWebSocketContext()
  const [subscribed, setSubscribed] = useState(false)
  const { user } = useAppSelector(envSelector)
  const userId = user?.uuid

  useEffect(() => {
    if (userId && connected && !subscribed) {
      sendMessage({ kind: MessageType.SUBSCRIBE, channel: `/user/${userId}` } as WsMessage<void>)
      setSubscribed(true)
    }
    return () => {
      if (userId && connected && subscribed) {
        sendMessage({ kind: MessageType.UNSUBSCRIBE, channel: `/user/${userId}` } as WsMessage<void>)
        setSubscribed(false)
      }
    }
  }, [userId, connected, subscribed, sendMessage])
}
