import Keycloak from 'keycloak-js'

const PROD_SSO = 'sso.myiris.gr'
const DEV_SSO = 'sso.fastmonkey.io'

const configure = () => window.location.host.includes('myiris') ? PROD_SSO : DEV_SSO

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'.
const keycloak = Keycloak({
  url: `https://${configure()}/auth/`,
  realm: 'iris',
  clientId: 'iris'
})

export default keycloak
