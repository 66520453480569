import { UserData, UserToken } from './user'
import { RoomId, UserJoinId, UUID } from './index'

export enum RoomType {
  POLICE = 'POLICE',
  FIRE_DEPARTMENT = 'FIRE_DEPARTMENT',
  AMBULANCE = 'AMBULANCE',
  CIVIL_PROTECTION = 'CIVIL_PROTECTION',
  SIMPLE = 'SIMPLE'
}

export const RoomTypeTranslation = {
  [RoomType.POLICE]: 'Αστυνομία',
  [RoomType.FIRE_DEPARTMENT]: 'Πυροσβεστική',
  [RoomType.AMBULANCE]: 'Ασθενοφόρο',
  [RoomType.CIVIL_PROTECTION]: 'Πολιτική προστασία',
  [RoomType.SIMPLE]: ''
}

export const RoomTypeTel = {
  [RoomType.POLICE]: '100',
  [RoomType.FIRE_DEPARTMENT]: '199',
  [RoomType.AMBULANCE]: '166',
  [RoomType.CIVIL_PROTECTION]: '112',
  [RoomType.SIMPLE]: ''
}

export const RoomTypeIcon = {
  [RoomType.POLICE]: '/img/icons/police.svg',
  [RoomType.FIRE_DEPARTMENT]: '/img/icons/fire.svg',
  [RoomType.AMBULANCE]: '/img/icons/ambulance.svg',
  [RoomType.CIVIL_PROTECTION]: '/img/icons/civil_protection.svg',
  [RoomType.SIMPLE]: ''
}

export interface CreateRoom {
  user_id: UUID,
  is_emergency: boolean
  invite_users: UUID[]
  room_type: RoomType
}

export interface Room {
  id: RoomId
  participants: { UUID: Participant } | {}
  creator: UserData
  target: UserData
  is_emergency: boolean
  room_type: RoomType
  line: number
  created_date: number
  end_date?: number
  in_call_date?: number
  has_interpreter: boolean
  created_by_interpreter: boolean
  status: CallStatus
}

export interface RoomInfo {
  id: RoomId,
  pin?: string,
  user_join_id: UserJoinId,
}

export interface Participant {
  user_id: UUID
  data: UserData
  is_interpreter: boolean
  joined_date?: number
}

export interface ChatMessage {
  id: number
  date: number
  user: UserToken,
  message: string
}

export enum CallStatus {
  ERROR = 'ERROR',
  CLOSED = 'CLOSED',
  NOT_ANSWERED = 'NOT_ANSWERED',
  IN_CALL = 'IN_CALL',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
}

export const CallStatusTranslation = {
  ERROR: 'Πρόβλημα',
  CLOSED: 'Ολοκληρώθηκε',
  NOT_ANSWERED: 'Αναπάντητη',
  IN_CALL: 'Σε κλήση',
  PENDING: 'Αναμονή',
  REJECTED: 'Απορρίφθηκε',
}

export const CallStatusColor = {
  ERROR: '#000000',
  CLOSED: '#97C255',
  NOT_ANSWERED: '#CA2627',
  PENDING: '#F7B551',
  REJECTED: '#CA2627',
}

export interface CallHistory {
  room: RoomId
  created_date: number
  room_type: RoomType
  status: CallStatus
}

export interface RoomMetrics {
  calls_not_answered: number
  users_waiting: number
  connected_interpreters: number
}
