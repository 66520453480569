import React from 'react'
import './ErrorPage.css'
import SideNav from '../../components/nav/SideNav'

export const ErrorPage: React.FC = ({ children }) => {
  return (
    <>
      <SideNav />
      <p className="top">
        <h1>Oops!</h1>
        <h3>Η σελίδα δεν βρέθηκε..</h3>
      </p>
      <div className="container">
        <div className="ghost-copy">
          <div className="one" />
          <div className="two" />
          <div className="three" />
          <div className="four" />
        </div>
        <div className="ghost">
          <div className="face">
            <div className="eye" />
            <div className="eye-right" />
            <div className="mouth" />
          </div>
        </div>
        <div className="shadow" />
      </div>
    </>
  )
}

export default ErrorPage
