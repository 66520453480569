import React from 'react'
import { exists } from '../../../misc/utils/utils'
import { Button, Col, Grid, IconButton, Modal, Row } from 'rsuite'
import CloseIcon from '@rsuite/icons/Close'
import { closeEmergencyModal, interpreterSelector } from '../../../features/interpreter/interpreterSlice'
import { Room, RoomType, RoomTypeIcon, RoomTypeTel, RoomTypeTranslation } from '../../../misc/domain/room'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import OnlineUser from '../../../components/user/OnlineUser'

export interface EmergencyModalProps {
  selectedRoom: (room: Room) => void
}

const EmergencyModal: React.FC<EmergencyModalProps> = ({ selectedRoom }) => {
  const dispatch = useAppDispatch()
  const { emergency, connectedUsers } = useAppSelector(interpreterSelector)
  const user = connectedUsers.find(u => u.user_id === emergency?.creator.id)?.data

  return <Modal backdrop="static" keyboard={false} open={exists(emergency)}>
    <Modal.Body style={{ padding: 0, margin: 0 }}>
      <Grid fluid>
        <Row>
          <Col xs={2} sm={2} md={2}
               style={{ backgroundColor: '#D06662', height: 180, borderTopLeftRadius: 10, borderBottomLeftRadius: 10 }}>
            <aside style={{ display: 'block', position: 'relative' }}>
              <img style={{ paddingTop: 3, paddingLeft: 3 }} src={RoomTypeIcon[emergency?.room_type ?? '']} alt=""/>
              <p style={{
                fontSize: 25,
                fontWeight: 600,
                color: 'white',
                marginTop: 100,
                transform: 'rotate(-90deg)',
              }}>{RoomTypeTel[emergency?.room_type ?? '']}</p>
            </aside>
          </Col>
          <Col xs={22} sm={22} md={22} style={{ padding: 10, height: 180 }}>

            <span style={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton icon={<CloseIcon/>} size={'lg'} onClick={() => dispatch(closeEmergencyModal())}/>
            </span>

            <p style={{ paddingBottom: 10, fontFamily: 'Alegreya Sans', fontSize: 25, fontWeight: 500 }}>
              Έκτακτη ανάγκη για {RoomTypeTranslation[emergency?.room_type ?? RoomType.SIMPLE]}
            </p>

            <div style={{ paddingTop: 10, paddingBottom: 10 }}>
              {emergency && user && <OnlineUser user={user} showBadge showName/>}
            </div>

            <div style={{ float: 'right', paddingTop: 5 }}>
              <Button size={'md'} appearance={'primary'}
                      style={{ backgroundColor: '#D06662' }}
                      onClick={() => {
                        let room: Room = { ...emergency as Room }
                        selectedRoom(room)
                        dispatch(closeEmergencyModal())
                      }}>
                <img src="/img/icons/call.svg" alt="Απάντηση"/>
                <span style={{ paddingLeft: 10 }}>Απάντηση</span>
              </Button>
            </div>
          </Col>
        </Row>
      </Grid>
    </Modal.Body>
  </Modal>
}

export default EmergencyModal
