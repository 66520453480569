import { useEffect, useRef } from 'react'

// An implementation of `useInterval` hook
// Original source: https://www.30secondsofcode.org/blog/s/react-use-interval-explained
export const useInterval = (callback: () => void, delay: number, immediate: boolean = true) => {
  const savedCallback = useRef<() => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const call = () => {
      if (savedCallback.current) {
        savedCallback.current()
      }
    }

    // If immediate it set call immediately once upon starting
    if (immediate) {
      call()
    }

    let id = setInterval(call, delay)
    return () => clearInterval(id)
  }, [delay, immediate])
}
