import React, { useEffect } from 'react'
import AppRoutes from './AppRoutes'
import { useDispatch } from 'react-redux'
import { initializeFirebase } from '../firebase/config'
import { useKeycloak } from '@react-keycloak/web'
import { Loader } from 'rsuite'
import { userTokenFromKc } from '../misc/utils/user'
import { envSelector, setUser } from '../features/environment/environmentSlice'
import { useAuthStateUpdate } from '../auth/useAuthStateUpdate'
import { useAppSelector } from './hooks'
import './App.css'
import 'rsuite/dist/rsuite.min.css'


export const App: React.FC = () => {
  // Initialize the firebase app.
  initializeFirebase()
  const { initialized, keycloak } = useKeycloak()
  const { user } = useAppSelector(envSelector)

  const dispatch = useDispatch()

  useAuthStateUpdate()

  // The App component listens continuously for changes in the auth context
  // and updates the auth status accordingly
  useEffect(() => {
    if (initialized && keycloak) {
      let user = userTokenFromKc(keycloak)
      dispatch(setUser(user))
    }

  }, [dispatch, initialized, keycloak])

  if (!user) {
    return (<Loader content="Loading..." center size="md"/>)
  }

  return (
    <AppRoutes/>
  )
}
