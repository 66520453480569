import { UserToken, UserWithHistory } from './user'
import { Room, RoomInfo, RoomMetrics } from './room'
import { UUID } from './index'

export enum MessageType {
  SEND = 'SEND',
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE'
}

export interface WsMessageHeaders {
  token: UserToken
  timestamp: number
}

export interface WsMessage<T> {
  kind: MessageType
  channel: string
  headers?: WsMessageHeaders
  payload?: T
}


export interface WsRoom {
  room: Room
}

export interface WsRoomCreated {
  room: Room
}

export interface WsInterpreterUpdate {
  room_metrics: RoomMetrics
  rooms: Room[]
  users: UserWithHistory[]
}

export interface WsUserUpdate {
  room_metrics: RoomMetrics
}

export interface WsJoin {
  invitation: RoomInfo
}

export interface WsUserSession{
  id: UUID
}
