import { FirebaseApp, getApps, initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyC7tQoUXSRXWFgttyAB1hrnYAGbl2chY4E',
  authDomain: 'relay-327615.firebaseapp.com',
  databaseURL: 'https://relay-327615-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'relay-327615',
  storageBucket: 'relay-327615.appspot.com',
  messagingSenderId: '599400103851',
  appId: '1:599400103851:web:32b9fe1011e7e9bbf11cee'
}

let firebaseApp: FirebaseApp

if (!getApps.length) {
  firebaseApp = initializeApp(firebaseConfig)
}

// @ts-ignore
const fireStore = getFirestore(firebaseApp)
// @ts-ignore
const storage = getStorage(firebaseApp)

const initializeFirebase = () => {
  console.log('Initializing firebase..')
}

export { initializeFirebase, fireStore, storage }

