import {Col, FlexboxGrid, Grid, Row} from 'rsuite'
import Stats from './Stats'
import InCallMain from './InCallMain'
import Emergency from './Emergency'
import {useUserSubscriptions} from '../../../ws/useUserSubscriptions'
import {useAppSelector} from '../../../app/hooks'
import {userSelector} from '../../../features/user/userSlice'
import {useUsersSubscriptions} from '../../../ws/useUsersSubscriptions'
import React from 'react'
import BeforeCallUser from '../../video/before-call-user/BeforeCallUser'

const HomeUserPage = () => {
  const { roomMetrics, roomCreated, room } = useAppSelector(userSelector)
  useUserSubscriptions()
  useUsersSubscriptions()

  return (
    <>
      {(roomCreated || room) && <BeforeCallUser/>}
      <Grid fluid>
        <Row>
          <Col xs={4} sm={4} md={4} style={{ backgroundColor: 'white' }}>
            <Stats interpreters={roomMetrics.connected_interpreters} pending={roomMetrics.users_waiting}/>
          </Col>
          <Col xs={17} sm={17} md={17} style={{ backgroundColor: '#1F3539' }}>
            <InCallMain/>
          </Col>
          <Col xs={3} sm={3} md={3} style={{ backgroundColor: '#28454D' }}>
            <Emergency/>
          </Col>
        </Row>
      </Grid>
      <div style={{width: "100%", height: 25, position: "fixed", bottom: 0}}>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={12} style={{ textAlign: 'center'}}>
            <a style={{color: 'gray'}} href={"https://myiris.gr/%cf%80%ce%bf%ce%bb%ce%b9%cf%84%ce%b9%ce%ba%ce%ae-%ce%b1%cf%80%ce%bf%cf%81%cf%81%ce%ae%cf%84%ce%bf%cf%85-iris/"} target={"_blank"} rel="noreferrer">
              Πολιτική Απορρήτου
            </a>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={12} style={{ textAlign: 'center'}}>
            <a style={{color: 'gray'}} href={"https://myiris.gr/%cf%8c%cf%81%ce%bf%ce%b9-%cf%87%cf%81%ce%ae%cf%83%ce%b7%cf%82/"} target={"_blank"} rel="noreferrer">
              Όροι Χρήσης
            </a>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </>
  )
}

export default HomeUserPage
