import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app/store'

export interface AuthState {
  token: string
}

const initialState: AuthState = {
  token: ''
}

const auth = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth(state, action: PayloadAction<string>) {
      state.token = action.payload
    }
  },
})

export default auth.reducer
export const { setAuth } = auth.actions
export const authSelector = (state: RootState) => state.auth
