import Participant from '../participant/Participant'
import { Button, FlexboxGrid, IconButton, Input, Tooltip, Whisper } from 'rsuite'
import SentToUserIcon from '@rsuite/icons/SentToUser'
import WechatOutlineIcon from '@rsuite/icons/WechatOutline'
import Clock from '../Clock'
import SendIcon from '@rsuite/icons/Send'
import CopyIcon from '@rsuite/icons/Copy'
import VideoChat from '../chat/VideoChat'
import React, { useState } from 'react'
import { RemoteVideo } from 'janus-gateway-tsdx'
import { UserData } from '../../../misc/domain/user'
import { ChatMessage, Room, RoomTypeIcon } from '../../../misc/domain/room'
import './InCallDesktop.css'
import { RoomId, UUID } from '../../../misc/domain'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { closeEmergencyModal, interpreterSelector } from '../../../features/interpreter/interpreterSlice'
import OnlineUser from '../../../components/user/OnlineUser'
import CloseIcon from '@rsuite/icons/Close'
import { getJoinRoom } from '../../../api/rest'

export interface InCallProps {
  room: RoomId
  user?: UserData
  localStream: MediaStream | null
  remoteStreams: any
  chatMessages: ChatMessage[],
  hasNewMessage: boolean,
  setHasNewMessages: (value: boolean) => void
}

const InCallDesktop: React.FC<InCallProps> = ({ room, user, localStream, remoteStreams, chatMessages }) => {

  const dispatch = useAppDispatch()
  const [showShare] = useState(false)
  const [shareLink,] = useState(window.location.href)
  const [showChat, setShowChat] = useState(true)

  const { emergency, connectedUsers } = useAppSelector(interpreterSelector)
  const emergencyUser = connectedUsers.find(u => u.user_id === emergency?.creator.id)?.data

  let participants = Object.values(remoteStreams).map(f => {
    let feed = f as RemoteVideo
    let feedId = feed.feedInfo.id.toString()
    let colspan = 24 / Object.keys(remoteStreams).length

    // eslint-disable-next-line no-restricted-globals
    // let videoHeight = (screen.height - screen.height * 0.20) / Object.keys(remoteStreams).length

    return (
      <FlexboxGrid.Item key={feedId?.toString()} colspan={colspan}>
        <Participant src={feed.stream} key={feedId?.toString()} username={feed.feedInfo.display} canCollapse={false}
                     videoHeight="85vh"/>
      </FlexboxGrid.Item>
    )
  })

  return (
    <>

      {emergency && user?.token.roles.includes('ROLE_IRIS_INTERPRETER') &&
        <div style={{ position: 'fixed', width: '100%', zIndex: 10000 }}>
          <div style={{
            margin: 15,
            padding: 15,
            backgroundColor: '#FFF2F2',
            borderRadius: 6,
          }}>
            <IconButton icon={<CloseIcon/>}
                        size={'xs'}
                        style={{ position: 'absolute', right: 20, top: 20 }}
                        onClick={() => dispatch(closeEmergencyModal())}
                        appearance={'subtle'}/>
            <img src={RoomTypeIcon[emergency.room_type]} width="50" height="50"
                 style={{ backgroundColor: '#D06662', padding: 7, borderRadius: 50 }} alt=""/>
            <span style={{
              fontFamily: 'Alegreya Sans',
              fontWeight: 500,
              fontSize: 25,
              lineHeight: '25px',
              paddingLeft: 10
            }}>
              Εισερχόμενη κλήση έκτακτης ανάγκης
            </span>
            <span style={{ float: 'right' }}>
                {emergency && emergencyUser && <OnlineUser user={emergencyUser} showBadge showName/>}
              <Button size={'md'} appearance={'primary'}
                      style={{ backgroundColor: '#D06662', marginLeft: 200 }}
                      onClick={() => {
                        let room: Room = { ...emergency as Room }
                        dispatch(getJoinRoom({ userId: user?.id as UUID, roomId: room.id }))
                        dispatch(closeEmergencyModal())
                      }}>
                <img src="/img/icons/call.svg" alt="Απάντηση"/>
                <span style={{ paddingLeft: 10 }}>Απάντηση</span>
              </Button>
            </span>
          </div>
        </div>
      }

      <div className={showShare ? 'video-local video-local-share' : 'video-local'}>
        {
          localStream &&
          <Participant src={localStream} muted={true} username="Εσείς" canCollapse={true}/>
        }
      </div>

      <div className="main-container">

        <div className={showChat ? 'video-container video-container-chat' : 'video-container'}>
          <div className="video-remote">
            {participants.length > 0 &&
              <FlexboxGrid align={'middle'} justify={'center'} style={{ height: '100%' }}>{participants}</FlexboxGrid>}
            {participants.length <= 0 &&
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                <span style={{ color: 'white', fontWeight: 500, fontFamily: 'Alegreya Sans', fontSize: 25 }}>
                  Παρακαλώ περιμένετε για συμμετέχοντες..
                </span>
              </div>
            }
          </div>
          <div className="video-actions">
            <div className="video-actions-center">
              <IconButton icon={<WechatOutlineIcon/>}
                          size={'lg'}
                          style={{ backgroundColor: '#3B888C', marginLeft: 5 }}
                          onClick={() => setShowChat(!showChat)}
                          appearance={'primary'}/>

              <span style={{ position: 'absolute', right: 40 }}>
                <Clock/>
                <Button size={'lg'} appearance={'primary'}
                        style={{ backgroundColor: '#9F4040', marginLeft: 10 }}
                        onClick={() => window.location.href = '/'}>
                  <img src="/img/icons/call.svg" alt="Απάντηση"/>
                  <span style={{ paddingLeft: 10 }}>Τερματισμός κλήσης</span>
                </Button>
              </span>
            </div>
            {showShare && <div style={{ backgroundColor: 'white', height: 80 }}>
              <div style={{ padding: '20px 30px 0px 30px', fontSize: 22, lineHeight: 2, fontFamily: 'Alegreya Sans' }}>
                <SentToUserIcon/>
                <span style={{ marginLeft: 5 }}>Πρόσκληση</span>
                <Input size={'lg'} style={{ width: '28%', display: 'inline-block', marginLeft: 20 }}
                       placeholder="Αποστολή προόσκλησης σε email..."/>
                <IconButton icon={<SendIcon/>} appearance={'primary'}
                            style={{ backgroundColor: '#3B888C', marginLeft: 10 }}
                            size={'lg'}/>
                <div style={{
                  width: '45%',
                  fontFamily: 'Alegreya Sans',
                  display: 'inline-block',
                  backgroundColor: '#EEF2F3',
                  borderRadius: 5,
                  paddingLeft: 15,
                  paddingRight: 15,
                  color: '#529599',
                  float: 'right'
                }}>
                  <span>{shareLink}</span>
                  <Whisper trigger="click"
                           placement="top"
                           controlId="control-id-top"
                           speaker={<Tooltip>Αντιγράφηκε..</Tooltip>}>
                    <span style={{ float: 'right', cursor: 'pointer' }}
                          onClick={() => navigator.clipboard.writeText(shareLink)}>
                      <CopyIcon/>
                    </span>
                  </Whisper>
                </div>
              </div>
            </div>}
          </div>
        </div>

        {showChat &&
          <div className="chat">
            <VideoChat room={room} close={() => setShowChat(false)} user={user as UserData} messages={chatMessages}/>
          </div>
        }

      </div>

    </>
  )

}

export default InCallDesktop
