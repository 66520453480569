import React from 'react'

export const DisconnectPage: React.FC = () => {
  return (
    <div style={{ position: 'fixed', top: '25%', width: '100vw' }}>
      <p style={{
        textAlign: 'center',
        fontSize: 20,
        color: 'black',
        fontFamily: 'Alegreya Sans'
      }}>
        Έχετε συνδεθεί από διαφορετική συσκευή.
      </p>
      <p style={{ textAlign: 'center' }}>Πατήστε <a href="/">εδώ</a> για να συνδεθείτε ξανά.</p>
    </div>
  )
}

export default DisconnectPage
