import { fireStore } from '../config'
import { ChatMessage } from '../../misc/domain/room'
import { collection, doc, getDocs, onSnapshot, setDoc, Timestamp, Unsubscribe } from 'firebase/firestore'
import { RoomId } from '../../misc/domain'

const PREFIX = '/rooms'

const getMessages = async (room: RoomId) => {
  const col = collection(fireStore, `${PREFIX}/${room}/chat`)
  const docs = await getDocs(col)
  return docs.docs.map(doc => doc.data() as ChatMessage)
}

const getMessagesAsync: (room: RoomId, f: (messages: ChatMessage[]) => void) => Unsubscribe = (room, f) => {
  const c = collection(fireStore, `${PREFIX}/${room}/chat`)
  return onSnapshot(c, qs => {
    let messages: ChatMessage[] = []
    qs.forEach(doc => messages.push(doc.data() as ChatMessage))
    f(messages)
  })
}

const addMessage = async (room: RoomId, message: ChatMessage) => {
  let ts = Timestamp.fromDate(new Date()).toMillis()
  message.id = ts
  message.date = ts
  await setDoc(doc(fireStore, `${PREFIX}/${room}/chat/${message.id}`), message)
}

export const chatService = { getMessages, getMessagesAsync, addMessage }

