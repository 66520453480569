import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosResponse } from 'axios'
import { RootState } from '../app/store'
import { createAxios } from '../auth'

export enum Method { GET, POST, PUT, DELETE, PATCH }

export type PathType<Arg> = string | ((input: Arg) => string)

export type ApiRequest<Arg> = {
  baseUrl: string
  path: PathType<Arg>
  method: Method
}

export const IRIS_BASE_URL = '/api/iris/v1'
export function irisServerRequest<Arg>(method: Method, path: PathType<Arg>): ApiRequest<Arg> {
  return {
    baseUrl: IRIS_BASE_URL, method, path
  }
}

export function createApiThunk<Arg, Ret>(typePrefix: string, request: ApiRequest<Arg>) {
  return createAsyncThunk(typePrefix, async (input: Arg, api) => {
    let { getState, rejectWithValue } = api
    let state = getState() as RootState
    let axios = createAxios(state.auth.token)
    let requestPath = typeof request.path === 'string' ? request.path : request.path(input)
    let path = request.baseUrl + requestPath
    let resp: AxiosResponse<Ret>

    try {
      switch (request.method) {
        case Method.GET:
          resp = await axios.get(path)
          break
        case Method.POST:
          resp = await axios.post(path, input)
          break
        case Method.PATCH:
          resp = await axios.patch(path, input)
          break
        case Method.DELETE:
          resp = await axios.delete(path)
          break
        case Method.PUT:
          resp = await axios.put(path, input)
          break
      }
      return resp.data
    } catch (err) {
      // err as AxiosError
      let { data, status, statusText } = err.response
      return rejectWithValue({ data, status, statusText })
    }
  })
}

