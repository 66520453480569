import { Avatar, Col, FlexboxGrid, Grid, Row } from 'rsuite'
import { useUserSubscriptions } from '../../../ws/useUserSubscriptions'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user/userSlice'
import { useUsersSubscriptions } from '../../../ws/useUsersSubscriptions'
import React, { useEffect } from 'react'
import { CreateRoom, RoomType } from '../../../misc/domain/room'
import { postUserRoomCreate } from '../../../api/rest'
import { navigatePage } from '../../../misc/utils/navigation'
import { useNavigate } from 'react-router-dom'
import ExitIcon from '@rsuite/icons/Exit'
import { useAuthContext } from '../../../auth/AuthContext'
import BeforeCallUserMobile from '../../video/before-call-user/BeforeCallUserMobile'

const welcomeMessageStyle: React.CSSProperties = {
  color: 'white',
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '25px',
  lineHeight: '30px'
}

const welcomeMessageNameStyle: React.CSSProperties = {
  color: 'white',
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '25px',
  lineHeight: '30px'
}

const callUsStyle: React.CSSProperties = {
  color: 'white',
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '20px',
  lineHeight: '20px',
  textAlign: 'center'
}

const statsContainerStyle: React.CSSProperties = {
  marginTop: 10,
  backgroundColor: '#28454D',
  borderRadius: 25,
  padding: 10,
}

const statsNumberStyle: React.CSSProperties = {
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '60px',
  lineHeight: '30px',
  color: '#B0DA7C',
  textAlign: 'center',
}

const statsImageStyle: React.CSSProperties = {
  marginTop: 20,
  padding: 15,
  borderRadius: 150,
  backgroundColor: '#233C42',
  textAlign: 'center',
  width: '5em',
  height: '5em'
}

const statsLabelStyle: React.CSSProperties = {
  marginTop: 10,
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '14px',
  lineHeight: '16px',
  color: '#B0DA7C',
  textAlign: 'center'
}

const callButtonStyle: React.CSSProperties = {
  width: '5em',
  height: '5em',
  backgroundColor: '#3B888C',
  padding: 10,
  cursor: 'pointer',
  borderRadius: '50%',
}

const emergencyContainerStyle: React.CSSProperties = {
  marginTop: 10,
}

const emergencyTelContainerStyle: React.CSSProperties = {
  backgroundColor: '#28454D',
  borderRadius: 25,
  padding: 20,
  cursor: 'pointer',
}

const emergencyTelStyle: React.CSSProperties = {
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '45px',
  lineHeight: '26px',
  color: '#EFABAB',
  textAlign: 'center'
}

const emergencyTelLabelStyle: React.CSSProperties = {
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '25px',
  color: 'white',
  textAlign: 'center'
}

const HomeUserPage = () => {
  useUserSubscriptions()
  useUsersSubscriptions()

  const auth = useAuthContext()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user, room, roomMetrics, roomCreated } = useAppSelector(userSelector)

  // Upon room request completion navigate to room
  useEffect(() => {
    if (room) {
      const { id } = room
      const path = `/rooms/${id}`

      navigatePage(dispatch, navigate, path, { state: { room } })
    }
  }, [room, dispatch, navigate])

  const call = (is_emergency: boolean, room_type: RoomType) => {
    if (user && !room) {
      // Room has not been requested
      let room: CreateRoom = { user_id: user.id, is_emergency, invite_users: [], room_type }
      dispatch(postUserRoomCreate(room))
    }
  }

  return (
    <>
      {(roomCreated || room) && <BeforeCallUserMobile/>}
      <Grid fluid style={{ backgroundColor: '#1F3539', height: '100%', width: '100%', padding: 30, paddingTop: 50 }}>
        <Row>
          <Col xs={19} sm={19} md={19}>
            <p style={welcomeMessageStyle}>Καλώς ήλθες</p>
            <p style={welcomeMessageNameStyle}>
              <span onClick={() => navigatePage(dispatch, navigate, '/user')}>{user?.token?.first_name}</span>
              <span onClick={() => auth.logout()} style={{ paddingLeft: 10, fontSize: 22 }}>
          <ExitIcon/>
        </span>
            </p>
          </Col>
          <Col xs={5} sm={5} md={5}>
            <Avatar circle src={user?.info.person.image ?? '/img/avatar.svg'} size="lg"
                    style={{ border: '1px solid white', float: 'right', cursor: 'pointer' }}
                    onClick={() => navigatePage(dispatch, navigate, '/user')}/>
          </Col>
        </Row>

        <Row style={{ paddingTop: 30 }}>
          <p style={callUsStyle}>Κάλεσέ μας</p>
          <div style={statsContainerStyle}>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={7} style={{ textAlign: 'center', paddingTop: 10 }}>
                <p style={statsNumberStyle}>{roomMetrics.connected_interpreters}</p>
                <img src="/img/home/interpreter.svg" alt="διερμηνείς" style={statsImageStyle}/>
                <p style={statsLabelStyle}>διερμηνείς σε σύνδεση</p>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={10} style={{ textAlign: 'center' }}>
                <img src="/img/home/eik.png" alt="Ίδρυμα κοφών"
                     style={{
                       border: '1px solid white',
                       borderRadius: '50%',
                       backgroundColor: '#F6F4F4',
                       padding: 7,
                       width: '8em',
                       height: '8em'
                     }}/>
                <div style={{ paddingTop: 15 }}>
                  <img src="/img/icons/call.svg" style={callButtonStyle} alt="Κάλεσε τώρα"
                       onClick={() => call(false, RoomType.SIMPLE)}/>
                </div>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={7} style={{ textAlign: 'center', paddingTop: 10 }}>
                <p style={statsNumberStyle}>{roomMetrics.users_waiting}</p>
                <img src="/img/home/deaf.svg" alt="χρήστες σε αναμονή" style={statsImageStyle}/>
                <p style={statsLabelStyle}>χρήστες σε αναμονή</p>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Row>

        <Row style={{ paddingTop: 20 }}>
          <p style={callUsStyle}>Κλήσεις έκτακτης ανάγκης:</p>
          <div style={emergencyContainerStyle}>
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={12} style={{ textAlign: 'center', paddingRight: 5 }}>
                <div style={emergencyTelContainerStyle}
                     onClick={() => call(true, RoomType.AMBULANCE)}>
                  <p style={emergencyTelStyle}>166</p>
                  <p style={emergencyTelLabelStyle}>Ασθενοφόρο</p>
                </div>
                <div style={{ ...emergencyTelContainerStyle, marginTop: 10 }}
                     onClick={() => call(true, RoomType.POLICE)}>
                  <p style={emergencyTelStyle}>100</p>
                  <p style={emergencyTelLabelStyle}>Αστυνομία</p>
                </div>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item colspan={12} style={{ textAlign: 'center', paddingLeft: 5 }}>
                <div style={emergencyTelContainerStyle}
                     onClick={() => call(true, RoomType.CIVIL_PROTECTION)}>
                  <p style={emergencyTelStyle}>112</p>
                  <p style={emergencyTelLabelStyle}>Π. Προστασία</p>
                </div>
                <div style={{ ...emergencyTelContainerStyle, marginTop: 10 }}
                     onClick={() => call(true, RoomType.FIRE_DEPARTMENT)}>
                  <p style={emergencyTelStyle}>119</p>
                  <p style={emergencyTelLabelStyle}>Πυροσβεστική</p>
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </div>
        </Row>
      </Grid>
      <div style={{width: "100%", height: 50, position: "fixed", bottom: 0}}>
        <FlexboxGrid>
          <FlexboxGrid.Item colspan={12} style={{ textAlign: 'center'}}>
            <a style={{color: 'gray'}} href={"https://myiris.gr/%cf%80%ce%bf%ce%bb%ce%b9%cf%84%ce%b9%ce%ba%ce%ae-%ce%b1%cf%80%ce%bf%cf%81%cf%81%ce%ae%cf%84%ce%bf%cf%85-iris/"} target={"_blank"} rel="noreferrer">
              Πολιτική Απορρήτου
            </a>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item colspan={12} style={{ textAlign: 'center'}}>
            <a style={{color: 'gray'}} href={"https://myiris.gr/%cf%8c%cf%81%ce%bf%ce%b9-%cf%87%cf%81%ce%ae%cf%83%ce%b7%cf%82/"} target={"_blank"} rel="noreferrer">
              Όροι Χρήσης
            </a>
          </FlexboxGrid.Item>
        </FlexboxGrid>
      </div>
    </>
  )
}

export default HomeUserPage
