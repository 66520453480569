import React, { useState } from 'react'
import { InCallProps } from '../in-call-desktop/InCallDesktop'
import {Badge, Button, FlexboxGrid, IconButton} from 'rsuite'
import VideoChat from '../chat/VideoChat'
import Participant from '../participant/Participant'
import { RemoteVideo } from 'janus-gateway-tsdx'
import { UserData } from '../../../misc/domain/user'
import WechatOutlineIcon from '@rsuite/icons/WechatOutline'
import Clock from '../Clock'

const InCallMobile: React.FC<InCallProps> = ({ room, user, localStream, remoteStreams, chatMessages, hasNewMessage, setHasNewMessages }) => {
  const [showChat, setShowChat] = useState(false)

  let participants = Object.values(remoteStreams).map(f => {
    let feed = f as RemoteVideo
    let feedId = feed.feedInfo.id.toString()
    let colspan = 24 / Object.keys(remoteStreams).length

    // eslint-disable-next-line no-restricted-globals
    // let videoHeight = (screen.height - (screen.height * 0.15) - 10) / Object.keys(remoteStreams).length

    return (
      <FlexboxGrid.Item key={feedId?.toString()} colspan={colspan}>
        <Participant src={feed.stream} key={feedId?.toString()} username={feed.feedInfo.display} canCollapse={false}/>
      </FlexboxGrid.Item>
    )
  })

  return (<>

    {showChat &&
      <div style={{ position: 'fixed', backgroundColor: 'white', zIndex: 1000 }}>
        <VideoChat room={room} close={() => setShowChat(false)} user={user as UserData} messages={chatMessages}/>
      </div>
    }

    {
      localStream &&
      <div style={{ position: 'fixed', bottom: '10vh', right: 10, zIndex: 10 }}>
        <Participant src={localStream} muted username="Εσείς" canCollapse collapsed height={130} width={130}/>
      </div>
    }

    <div style={{ backgroundColor: 'rgb(40 40 40)' }}>

      <div style={{ height: '85vh' }}>
        {participants.length > 0 &&
          <FlexboxGrid align={'middle'} justify={'center'} style={{ height: '100%' }}>{participants}</FlexboxGrid>}
        {participants.length <= 0 &&
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <p style={{ color: 'white', fontWeight: 500, fontFamily: 'Alegreya Sans', fontSize: 25, textAlign: 'center' }}>
              Παρακαλώ περιμένετε για συμμετέχοντες..
            </p>
          </div>
        }
      </div>

      <div style={{
        backgroundColor: '#1F3539',
        height: '15vh',
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        {
          hasNewMessage &&
          <Badge content={'Νέα'}>
            <IconButton icon={<WechatOutlineIcon/>}
                        size={'lg'}
                        style={{ backgroundColor: '#3B888C' }}
                        onClick={() => {
                          setShowChat(!showChat)
                          setHasNewMessages(false)
                        }}
                        appearance={'primary'}/>
          </Badge>
        }
        {
          !hasNewMessage &&
          <IconButton icon={<WechatOutlineIcon/>}
                      size={'lg'}
                      style={{ backgroundColor: '#3B888C' }}
                      onClick={() => {
                        setShowChat(!showChat)
                        setHasNewMessages(false)
                      }}
                      appearance={'primary'}/>
        }
        <Button size={'md'} appearance={'primary'}
                style={{ backgroundColor: '#9F4040', marginLeft: 10 }}
                onClick={() => window.location.href = '/'}>
          <img src="/img/icons/call.svg" alt=""/>
        </Button>
        <div style={{ position: 'absolute', right: 15 }}><Clock/></div>
      </div>

    </div>
  </>)
}

export default InCallMobile
