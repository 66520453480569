import HomeInterpreterPage from './interpreter/HomeInterpreterPage'
import { UserRole } from '../../misc/domain/user'
import HomeUserPage from './user/HomeUserPage'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { envSelector } from '../../features/environment/environmentSlice'
import { useUserSubscriptions } from '../../ws/useUserSubscriptions'
import { isMobile } from 'react-device-detect'
import { getJoinRoom, getUserMe, getUserSession, rejectRoom } from '../../api/rest'
import React, { useEffect } from 'react'
import HomeMobileUserPage from './user/HomeMobileUserPage'
import { closeIncomingModal, setSessionId, userSelector } from '../../features/user/userSlice'
import { exists } from '../../misc/utils/utils'
import { Button, IconButton, Modal } from 'rsuite'
import CloseIcon from '@rsuite/icons/Close'
import { RoomId } from '../../misc/domain'
import { v4 as uuidv4 } from 'uuid';
import { NAVIGATION } from '../../app/constants'

const HomePage = () => {
  const { user } = useAppSelector(envSelector)
  // const userState: UserState = useAppSelector(userSelector)
  const { incoming, sessionId, disconnect } = useAppSelector(userSelector)
  // const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useUserSubscriptions()

  useEffect(() => {
    if (user) {
      dispatch(getUserMe(user.uuid))
    }
  }, [dispatch, user])

  useEffect(() => {
    if (user && !sessionId) {
      const req = uuidv4()
      dispatch(setSessionId(req))
      dispatch(getUserSession({ userId: user.uuid, sessionId: req }))
    }
  }, [dispatch, sessionId, user])

  useEffect(() => {
    if (disconnect) {
      window.location.href = NAVIGATION.DISCONNECTED.url
    }
  }, [disconnect])

  // useEffect(() => {
  //   if (user && user.roles.includes(UserRole.USER) && absent(userState.user?.updated_info)) {
  //     navigatePage(dispatch, navigate, '/user')
  //   }
  // }, [dispatch, navigate, user, user?.roles, userState])

  if (!user) {
    return <>Should never render this...</>
  }
  return (
    <>
      <Modal backdrop="static" keyboard={false} full={isMobile} size="xs"
             open={exists(incoming) && user.roles.includes(UserRole.USER) && incoming?.creator.id !== user.uuid}>
        <Modal.Body style={{ padding: 0, margin: 0 }}>
          <span style={{ position: 'absolute', right: 0, top: 5 }}>
            <IconButton icon={<CloseIcon/>} size={'lg'} onClick={() => {
              dispatch(rejectRoom({ userId: user.uuid, roomId: incoming?.id as RoomId }))
              dispatch(closeIncomingModal())
            }}/>
          </span>

          <p style={{ paddingBottom: 10, fontFamily: 'Alegreya Sans', fontSize: 25, fontWeight: 500, width: '80%' }}>
            Εισερχόμενη κλήση
          </p>

          <div style={{ float: 'right', paddingTop: 20 }}>
            <Button size={'md'} appearance={'primary'}
                    style={{ backgroundColor: '#3C888C' }}
                    onClick={() => {
                      dispatch(getJoinRoom({ userId: user.uuid, roomId: incoming?.id as RoomId }))
                    }}>
              <img src="/img/icons/call.svg" alt="Απάντηση"/>
              <span style={{ paddingLeft: 10 }}>Αποδοχή</span>
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {user.roles.includes(UserRole.INTERPRETER) ? <HomeInterpreterPage/> :
        (isMobile ? <HomeMobileUserPage/> : <HomeUserPage/>)}
    </>
  )
}

export default HomePage
