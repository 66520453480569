const CallIcon =
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_777:20737)">
      <path
        d="M26.08 10.36C25.3699 7.19358 23.5183 4.39967 20.8788 2.51189C18.2393 0.624116 14.9969 -0.225231 11.7709 0.126068C8.54492 0.477367 5.56136 2.00469 3.39006 4.41634C1.21877 6.82799 0.0119179 9.95495 0 13.2L0 13.77V14.4C0.152926 16.0588 0.617738 17.6737 1.37 19.16L1.48 19.36L0 25.37V25.51V25.64C0.00751056 25.7671 0.0452211 25.8905 0.11 26C0.170876 26.1026 0.252647 26.1911 0.35 26.26C0.443345 26.3326 0.55334 26.3807 0.67 26.4C0.785307 26.4252 0.904693 26.4252 1.02 26.4L7.02 24.9L7.22 25.01C9.01769 25.9007 10.9938 26.3725 13 26.39C14.9911 26.4279 16.9646 26.0106 18.77 25.17C21.4979 23.9073 23.7127 21.7507 25.0476 19.0574C26.3824 16.364 26.7573 13.2955 26.11 10.36H26.08ZM15.56 16.7C15.5609 16.917 15.5189 17.1321 15.4365 17.3328C15.3542 17.5336 15.233 17.7161 15.08 17.87C14.7693 18.173 14.354 18.3449 13.92 18.35H9C8.56258 18.3475 8.14318 18.1754 7.83 17.87C7.67698 17.7161 7.55582 17.5336 7.47346 17.3328C7.39109 17.1321 7.34914 16.917 7.35 16.7V10.1C7.34867 9.88296 7.39044 9.66781 7.47289 9.46702C7.55534 9.26623 7.67683 9.08381 7.83031 8.93033C7.9838 8.77685 8.16622 8.65536 8.36701 8.57291C8.56779 8.49046 8.78295 8.44869 9 8.45001H13.95C14.1662 8.45001 14.3803 8.49276 14.5799 8.5758C14.7795 8.65884 14.9608 8.78054 15.1132 8.93389C15.2656 9.08724 15.3862 9.26921 15.468 9.46934C15.5499 9.66947 15.5913 9.88381 15.59 10.1L15.56 16.7ZM20.56 16.36C20.5629 16.5243 20.5139 16.6852 20.42 16.82C20.3284 16.9529 20.2005 17.0565 20.0515 17.1186C19.9025 17.1807 19.7388 17.1985 19.58 17.17C19.4204 17.1363 19.2744 17.0563 19.16 16.94L17.26 15.05V11.75L19.16 9.86001C19.2744 9.74375 19.4204 9.66375 19.58 9.63001C19.7388 9.6015 19.9025 9.61934 20.0515 9.68141C20.2005 9.74348 20.3284 9.84715 20.42 9.98001C20.5139 10.1148 20.5629 10.2758 20.56 10.44V16.36Z"
        fill="white"/>
    </g>
    <defs>
      <clipPath id="clip0_777:20737">
        <rect width="26.39" height="26.39" fill="white"/>
      </clipPath>
    </defs>
  </svg>

export default CallIcon
