import React from 'react'
import { Button } from 'rsuite'
import { cancelRoom } from '../../../api/rest'
import { RoomId } from '../../../misc/domain'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user/userSlice'
import { RoomInfo } from '../../../misc/domain/room'

const BeforeCallMain: React.FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(userSelector)
  const { roomInfoCreated } = useAppSelector(userSelector)

  const room = roomInfoCreated as RoomInfo

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#1F3539' }}>
      <p style={{ marginTop: '10%', textAlign: 'center', color: 'white', fontSize: 30, fontFamily: 'Alegreya Sans' }}>
        Είστε σε αναμονή..
      </p>
      <div style={{
        backgroundColor: '#28454D',
        borderRadius: 20,
        marginTop: 50,
        marginRight: '10%',
        marginLeft: '10%',
        height: '50%'
      }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/img/home/eik.png" alt="Ίδρυμα κοφών"
               style={{
                 border: '1px solid white',
                 borderRadius: '50%',
                 backgroundColor: '#F6F4F4',
                 padding: 20,
                 width: '170px',
                 height: '170px'
               }}/>
        </div>
      </div>
      <div style={{ marginTop: 100, textAlign: 'center' }}>
        <Button size={'lg'} appearance={'primary'}
                style={{ backgroundColor: '#9F4040' }}
                onClick={() => dispatch(cancelRoom({ userId: user?.id ?? '', roomId: room?.id as RoomId }))}>
          <img src="/img/icons/call.svg" alt="Απάντηση"/>
          <span style={{ paddingLeft: 10 }}>Ακύρωση</span>
        </Button>
      </div>
    </div>
  )
}

export default BeforeCallMain
