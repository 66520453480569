import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { CallStatus, Room, RoomInfo, RoomMetrics } from '../../misc/domain/room'
import { getJoinRoom, getUserMe, postUserRoomCreate } from '../../api/rest'
import { UserData, UserWithHistory } from '../../misc/domain/user'
import { wsMessage } from '../environment/environmentSlice'
import { WsInterpreterUpdate, WsJoin, WsRoomCreated } from '../../misc/domain/ws'

export interface InterpreterState {
  user?: UserData

  room?: RoomInfo
  roomCreated?: RoomInfo

  rooms: Room[]
  connectedUsers: UserWithHistory[]
  roomMetrics: RoomMetrics

  emergency?: Room
}

const initialState: InterpreterState = {
  rooms: [],
  connectedUsers: [],
  roomMetrics: {
    calls_not_answered: 0,
    users_waiting: 0,
    connected_interpreters: 0,
  }
}

const interpreter = createSlice({
  name: 'interpreter',
  initialState,
  reducers: {
    closeEmergencyModal(state) {
      state.emergency = undefined
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserRoomCreate.fulfilled, (state, { payload }) => {
        const { id, pin, user_join_id } = payload as RoomInfo
        state.roomCreated = { id, pin, user_join_id }
      })
      .addCase(postUserRoomCreate.rejected, (state, _) => {
        state.roomCreated = undefined
      })
      .addCase(getUserMe.fulfilled, (state, { payload }) => {
        state.user = payload as UserData
      })
      .addCase(getUserMe.rejected, (state, _) => {
        state.user = undefined
      })
      .addCase(wsMessage, (state, { payload: msg }) => {
        switch (msg.payload?.type) {
          case 'WsInterpreterUpdate':
            const { rooms, room_metrics, users } = msg.payload as WsInterpreterUpdate
            state.rooms = rooms
            state.roomMetrics = room_metrics
            state.connectedUsers = users

            if (state.emergency) {
              const er = state.rooms.find(r => r.id === state?.emergency?.id)
              if (er && er.status !== CallStatus.PENDING) state.emergency = undefined
            }
            break
          case 'WsRoomCreated':
            const { room } = msg.payload as WsRoomCreated
            if (room.is_emergency) state.emergency = room
            if (state?.user?.token?.roles.includes('ROLE_IRIS_INTERPRETER') ?? false) {
              new Audio('/sound/new_call_bell_2.mp3').play()
            }
            break
          case 'WsJoin':
            const { invitation } = msg.payload as WsJoin
            state.room = invitation
            state.roomCreated = undefined
            break
          default:
            break
        }
      })
      .addCase(getJoinRoom.fulfilled, (state, { payload }) => {
        const { id, pin, user_join_id } = payload as RoomInfo
        state.room = { id, pin, user_join_id }
        state.emergency = undefined
        state.roomCreated = undefined
      })
      .addCase(getJoinRoom.rejected, (state, _) => {
        state.room = undefined
        state.emergency = undefined
        state.roomCreated = undefined
      })
  }
})

// Convenient selector for environment
export const interpreterSelector = (state: RootState) => state.interpreter

// Reducer actions
export const { closeEmergencyModal } = interpreter.actions

// Reducer
export default interpreter.reducer
