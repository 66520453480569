import {UserToken} from '../domain/user'

export const userUuidKc = (keycloak) => {
  return keycloak.tokenParsed.sub
}

export const userTokenFromKc: (keycloak) => UserToken = (keycloak) => {
  return {
    uuid: keycloak.tokenParsed.sub,
    username: keycloak.tokenParsed.preferred_username,
    first_name: keycloak.tokenParsed.given_name,
    last_name: keycloak.tokenParsed.family_name,
    email: keycloak.tokenParsed.email,
    roles: keycloak.tokenParsed.realm_access.roles
  }
}

export const userFullNameKc = (keycloak) => {
  return `${keycloak.tokenParsed.given_name ?? ''} ${keycloak.tokenParsed.family_name ?? ''}`.trim()
}

export const userFullName = (user: UserToken) => {
  return `${user.first_name ?? ''} ${user.last_name ?? ''}`.trim()
}

export const userCapitalLetters = (user: UserToken) => {
  return `${user.first_name.charAt(0) ?? ''}${user.last_name.charAt(0) ?? ''}`.trim().toUpperCase()
}
