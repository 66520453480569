export function dateDiff(d1: Date, d2: Date) {
  let ms = d2.getTime() - d1.getTime()

  return {
    ms,
    days: Math.floor(ms / 86400000),
    hours: Math.floor((ms % 86400000) / 3600000),
    minutes: Math.round(((ms % 86400000) % 3600000) / 60000)
  }
}

export function dateDiffString(d1: Date, d2: Date) {
  let diff = dateDiff(d1, d2)

  if (diff.days > 0) return `${diff.days}μ πριν`
  if (diff.hours > 0) return `${diff.hours}ω  πριν`
  return `${diff.minutes}λ πριν`
}

export function dateDiffMinutes(d1: Date, d2: Date): number {
  let ms = d2.getTime() - d1.getTime()
  return Math.floor((ms / 1000) / 60)
}

