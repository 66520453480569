import { combineReducers } from '@reduxjs/toolkit'
import environment from '../features/environment/environmentSlice'
import auth from '../auth/authSlice'
import user from '../features/user/userSlice'
import interpreter from '../features/interpreter/interpreterSlice'


const rootReducer = combineReducers({
  environment,
  auth,
  user,
  interpreter,
})

export default rootReducer
