export const BASE_URL = '/api/dsp/v1'

export interface RoomParams {
  id: string | undefined
}

export const NAVIGATION = {
  HOME: {
    name: 'Home',
    url: '/home',
    icon: 'home'
  },

  USER: {
    name: 'User',
    url: '/user',
    icon: ''
  },

  ROOM: {
    name: 'Room',
    url: '/rooms/:id',
    icon: 'logo-video'
  },
  LOGIN: {
    name: 'Login',
    url: '/login'
  },
  DISCONNECTED: {
    name: 'Disconnected',
    url: '/disconnected'
  },
}

const PROD_WEBRTC = 'wss://webrtc.myiris.gr/ws'
const DEV_WEBRTC = 'wss://webrtc.fastmonkey.io/ws'
const LOCAL_WEBRTC = 'ws://localhost:8188/ws'

export const webrtcServer = () => {
  if (window.location.host.includes('myiris')) {
    return PROD_WEBRTC
  } else if (window.location.host.includes('fastmonkey')) {
    return DEV_WEBRTC
  } else {
    return LOCAL_WEBRTC
  }
}
