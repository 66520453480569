import * as React from 'react'
import { Navigate } from 'react-router-dom'
import { useAuthContext } from '../auth/AuthContext'
import { NAVIGATION } from './constants'


// Updated to v6 from https://medium.com/front-end-weekly/how-to-create-private-route-with-react-router-v6-fa4b9d74cb55
interface Props {
  component: React.ComponentType
}

export const PrivateRoute: React.FC<Props> = ({ component: RouteComponent }) => {
  const auth = useAuthContext()

  if (auth.isAuthenticated()) {
    return <RouteComponent/>
  }
  return <Navigate to={NAVIGATION.LOGIN.url}/>

}
