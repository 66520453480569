import { Avatar } from 'rsuite'
import { userFullName } from '../../misc/utils/user'
import React from 'react'
import { UserData } from '../../misc/domain/user'

export interface OnlineUsers {
  user: UserData,
  showBadge: boolean
  showName?: boolean
  showConnectionStatus?: boolean
}

const containerStyle: React.CSSProperties = {
  width: '100%',
  position: 'relative',
}

const badgeStyle: React.CSSProperties = {
  width: '10px',
  height: '10px',
  backgroundColor: '#97C255',
  position: 'absolute',
  borderRadius: '50%',
  bottom: 5,
  left: 29,
  zIndex: 1,
}

const connectionLabelStyle: React.CSSProperties = {
  width: 150,
  color: '#97C255',
  fontSize: 10,
  position: 'absolute',
  bottom: 3,
  left: 40,
  zIndex: 1,
}

const OnlineUser: React.FC<OnlineUsers> = ({ user, showBadge, showName, showConnectionStatus }) => {
  return (
    <span style={containerStyle}>
      {showBadge && <span>
        <div style={badgeStyle}/>
        {showConnectionStatus && <span style={connectionLabelStyle}>σε σύνδεση</span>}
      </span>}
      {user.info.person.image && <Avatar src={user.info.person.image} circle/>}
      {!user.info.person.image && <Avatar src="/img/avatar.svg" circle/>}
      {showName &&
        <span style={{
          paddingLeft: 10,
          paddingTop: 10,
          position: 'absolute',
          width: 250
        }}>{userFullName(user.token)}</span>}
    </span>
  )
}

export default OnlineUser
