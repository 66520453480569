import React, { useEffect, useRef, useState } from 'react'
import { Avatar, Col, Container, Content, Footer, Grid, Header, IconButton, Input, Row } from 'rsuite'
import WechatOutlineIcon from '@rsuite/icons/WechatOutline'
import CloseIcon from '@rsuite/icons/Close'
import SendIcon from '@rsuite/icons/Send'
import { UserData } from '../../../misc/domain/user'
import { ChatMessage } from '../../../misc/domain/room'
import { userFullName } from '../../../misc/utils/user'
import { chatService } from '../../../firebase/service/chat'
import { RoomId } from '../../../misc/domain'
import {isMobile} from "react-device-detect";

const headerStyle: React.CSSProperties = {
  fontSize: 25,
  textAlign: 'center',
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  lineHeight: '20px'
}

const messageStyle: React.CSSProperties = {
  marginBottom: 15
}

export interface VideoChatProps {
  close: () => void
  room: RoomId
  user: UserData
  userImage?: string
  messages: ChatMessage[]
}

const timeString = (dateTimestamp) => {
  const date = new Date(dateTimestamp)
  return `${date?.getHours()}:${date?.getMinutes()}`
}

const newMessage: (room, user: UserData, message) => ChatMessage = (room, user, message) => {
  return {
    id: 1,
    date: 1,
    user: user.token,
    user_image: user?.info?.person?.image ?? null,
    message
  }
}

const appendMessage = (room, user: UserData, msg, setMsg) => {
  msg = msg.trim()
  if (!msg) return
  chatService.addMessage(room, newMessage(room, user, msg))
    .then(() => setMsg(''))
}

const OtherUserMessage = ({ message }) => {
  return (
    <Grid fluid style={messageStyle}>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4}/>
        <Col xs={18} sm={18} md={18} lg={18}>
          <p style={{ color: '#A7A7A8', paddingLeft: 20 }}>{userFullName(message.user)}</p>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}/>
      </Row>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4}>
          <Avatar circle src={message.user_image ?? '/img/avatar.svg'}/>
        </Col>
        <Col xs={18} sm={18} md={18} lg={18}>
          <p style={{
            backgroundColor: '#F7F7FA',
            padding: 10,
            borderRadius: 10,
            float: 'left'
          }}>{message.message}</p>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}/>
      </Row>
      <Row>
        <Col xs={4} sm={4} md={4} lg={4}/>
        <Col xs={18} sm={18} md={18} lg={18}>
          <p style={{ color: '#A7A7A8' }}>{timeString(message.date)}</p>
        </Col>
        <Col xs={2} sm={2} md={2} lg={2}/>
      </Row>
    </Grid>
  )
}

const CurrentUserMessage = ({ message }) => {
  return (
    <Grid fluid style={messageStyle}>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6}/>
        <Col xs={18} sm={18} md={18} lg={18}>
          <p style={{
            backgroundColor: '#4AA0AD',
            padding: 10,
            borderRadius: 10,
            color: 'white',
            float: 'right'
          }}>{message.message}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={6} sm={6} md={6} lg={6}/>
        <Col xs={18} sm={18} md={18} lg={18}>
          <p style={{ color: '#A7A7A8', float: 'right' }}>{timeString(message.date)}</p>
        </Col>
      </Row>
    </Grid>
  )
}

const AlwaysScrollToBottom = () => {
  const elementRef = useRef<HTMLDivElement>(null)
  useEffect(() => elementRef?.current?.scrollIntoView())
  return <div ref={elementRef}/>
}

const Message = ({ user, message }) => {
  return user.uuid === message.user.uuid ?
    <CurrentUserMessage message={message}/> : <OtherUserMessage message={message}/>
}

const PADDING_MOBILE = isMobile ? 50 : 20;
const PADDING_MOBILE_BOT = isMobile ? 'calc(100vh - 340px)' : 'calc(100vh - 310px)';
const VideoChat: React.FC<VideoChatProps> = ({ room, close, user, messages }) => {

  const [msg, setMsg] = useState('')

  return (
    <Container style={{ padding: 20, paddingTop: PADDING_MOBILE }}>

      <Header style={{ height: 170 }}>
        <p style={headerStyle}>
          <WechatOutlineIcon style={{ marginRight: 5 }}/>
          <span>Μηνύματα</span>
          <IconButton icon={<CloseIcon/>}
                      size={'lg'}
                      style={{ float: 'right' }}
                      onClick={close}
                      appearance={'subtle'}
          />
        </p>
        <p style={{ backgroundColor: '#EEF2F3', padding: 15, borderRadius: 5, marginTop: 15 }}>
          <span
            style={{ color: '#3B888C', fontWeight: 700 }}>Ανταλάξτε μηνύματα με τους συμμετέχοντες στην κλήση.</span>
          <br/>
          <br/>
          <span style={{ fontWeight: 400 }}>
            Τα μηνύματα είναι ορατά μόνο εντώς της κλήσης και διαγράφονται μετά τον τερματισμό της.
          </span>
        </p>
      </Header>

      <Content style={{ height: PADDING_MOBILE_BOT, marginTop: 40, marginBottom: 20 }}>
        <div style={{ overflowY: 'auto', height: '100%', width: '100%' }}>
          {messages.map(m => <Message user={user.token} message={m} key={m.id}/>)}
          <AlwaysScrollToBottom/>
        </div>
      </Content>

      <Footer style={{ height: 40, display: 'flex' }}>
        <Input as="textarea" placeholder="Γράψτε ένα μήνυμα.."
               value={msg}
               onChange={txt => setMsg((txt as string).replace('\n', ''))}
               onPressEnter={() => appendMessage(room, user, msg, setMsg)}/>
        <IconButton icon={<SendIcon/>} appearance={'primary'}
                    style={{ backgroundColor: '#3B888C', marginLeft: 5 }}
                    onClick={() => appendMessage(room, user, msg, setMsg)}/>
      </Footer>
    </Container>
  )
}

export default VideoChat
