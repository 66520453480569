import React, { useCallback, useEffect, useState } from 'react'
import { Avatar, Button, Input, InputGroup, Popover, Whisper } from 'rsuite'
import { UserWithHistory } from '../../../misc/domain/user'
import OnlineUser from '../../../components/user/OnlineUser'
import { Search } from '@rsuite/icons'
import { userFullName } from '../../../misc/utils/user'
import { useAuthContext } from '../../../auth/AuthContext'
import ExitIcon from '@rsuite/icons/Exit'
import InfoOutlineIcon from '@rsuite/icons/InfoOutline'
import { CallHistory, CallStatusTranslation, CreateRoom, RoomType } from '../../../misc/domain/room'
import { postUserRoomCreate } from '../../../api/rest'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { envSelector } from '../../../features/environment/environmentSlice'
import { dateDiffString } from '../../../misc/utils/date'
import { CallIcon } from './Calls'

const logoStyle: React.CSSProperties = {
  paddingLeft: '65px',
  paddingRight: '65px',
  paddingTop: '20px',
  paddingBottom: '20px',
  height: 120
}

const labelsStyle: React.CSSProperties = {
  paddingBottom: '10px',
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 800,
  fontSize: '14px',
  lineHeight: '20px',
  textTransform: 'uppercase',
  color: '#575757'
}

export interface OnlineUsersProps {
  users: UserWithHistory[]
}

const OnlineUsers: React.FC<OnlineUsersProps> = (props) => {
  const auth = useAuthContext()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(envSelector)
  const [name, setName] = useState('')
  const [foundUsers, setFoundUsers] = useState(props.users)

  const filter = useCallback((_, e) => setName(e.target.value), [])

  useEffect(() => {
    if (name !== '') {
      const results = props.users.filter((user) => {
        // Use the toLowerCase() method to make it case-insensitive
        return userFullName(user.data.token).toLowerCase().includes(name.toLowerCase())
      })
      setFoundUsers(results)
    } else {
      setFoundUsers(props.users)
    }
  }, [name, props.users])

  let users = foundUsers.map(u => {

    const createPopOver = () => {
      const mostRecent: CallHistory | undefined =
        Object.values(u.call_history).sort((a, b) => b.created_date - a.created_date)[0]

      return (
        <Popover style={{ width: 250, padding: 0 }}>
          <div style={{
            height: 50,
            backgroundColor: '#4AA0AD',
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            padding: 10
          }}>

            <span style={{ position: 'absolute', top: 30 }}>
              <OnlineUser user={u.data} showBadge showConnectionStatus/>
            </span>
          </div>

          <p style={{
            fontFamily: 'Alegreya Sans',
            fontWeight: 600,
            fontSize: 18,
            paddingTop: 30,
            paddingLeft: 10,
          }}>{userFullName(u.data.token)}</p>

          <>
            <p style={{
              marginLeft: 10,
              marginRight: 10,
              color: '#9B9B9B',
              fontFamily: 'Alegreya Sans',
              fontSize: 12,
              borderBottom: '1px solid #F2F2F5',
            }}>
              <span>τελευταία κλήση</span>
              <span style={{ float: 'right' }}>
                {mostRecent && <span>{dateDiffString(new Date(mostRecent.created_date), new Date())}</span>}
                {!mostRecent && <span>-</span>}
              </span>
            </p>
            <p style={{ textAlign: 'center', fontWeight: 400, fontSize: 14, color: '#575757' }}>
              {mostRecent && <span>
                <CallIcon status={mostRecent.status}/>
                <span style={{ paddingLeft: 5 }}>{CallStatusTranslation[mostRecent.status]}</span>
              </span>}
              {!mostRecent && <span>Δεν βρέθηκε</span>}
            </p>
            <p style={{ marginLeft: 10, marginRight: 10, borderTop: '1px solid #F2F2F5' }}>&nbsp;</p>
          </>

          <div style={{ padding: 10, textAlign: 'center', paddingTop: 0 }}>
            <Button size={'sm'} appearance={'primary'}
                    style={{ backgroundColor: '#3B888C' }}
                    onClick={() => {
                      let room: CreateRoom = {
                        user_id: user?.uuid as any,
                        is_emergency: false,
                        invite_users: [u.user_id],
                        room_type: RoomType.SIMPLE
                      }
                      dispatch(postUserRoomCreate(room))
                    }}>
              <img src="/img/icons/call.svg" alt="Νέα κλήση"/>
              <span style={{ paddingLeft: 10 }}>Νέα κλήση</span>
            </Button>
          </div>
        </Popover>
      )
    }

    return (
      <span key={u.user_id}>
        <div style={{ marginBottom: 5, position: 'relative' }}>
          <OnlineUser user={u.data} showBadge showName/>
          <Whisper placement="right" trigger="hover" speaker={createPopOver()} enterable>
            <span style={{ position: 'absolute', right: 0, top: 10 }}><InfoOutlineIcon/></span>
          </Whisper>
        </div>
      </span>
    )
  })

  return (
    <div style={{ height: '100vh', overflowY: 'auto' }}>
      <div style={logoStyle}>
        <img width={'100%'} src="/img/logo.svg" alt="Iris"/>
      </div>

      <div style={{ height: 80, padding: 10 }}>
        <p style={labelsStyle}>ΧΡΗΣΤΕΣ</p>
        <InputGroup>
          <Input placeholder="Αναζήτηση" type="search" value={name} onChange={filter}/>
          <InputGroup.Addon>
            <Search/>
          </InputGroup.Addon>
        </InputGroup>
      </div>
      <div style={{ height: 'calc(100vh - 270px)', overflowY: 'auto', padding: 10 }}>{users}</div>
      <div style={{ color: '#575757', position: 'relative', paddingTop: 10 }}>
        <div style={{ height: 1, backgroundColor: '#ECF0F1', marginBottom: 10 }}/>
        <Avatar circle src="/img/avatar.svg" style={{ border: '1px solid white', marginLeft: 10 }}/>
        <span style={{ position: 'absolute', left: 60, top: 28, fontSize: 16 }}>εσείς</span>
        <span onClick={() => auth.logout()}
              style={{ position: 'absolute', right: 20, top: 28, fontSize: 18, cursor: 'pointer' }}>
          <ExitIcon/>
        </span>
      </div>
    </div>
  )
}

export default OnlineUsers
