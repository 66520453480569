import { RoomId, UUID } from './index'
import { CallHistory } from './room'

export enum UserRole {
  INTERPRETER = 'iris_interpreter',
  USER = 'iris_user'
}

export interface UserToken {
  uuid: UUID
  username: string
  first_name: string
  last_name: string
  email: string
  roles: string[]
}

export interface UserData {
  id: UUID
  token: UserToken
  info: UserInfo
  updated_info?: number
  room?: RoomId
}

export interface Person {
  first_name?: string
  last_name?: string
  email?: string
  phone?: string
  address?: string
  kepa_id?: string
  image?: string
}

export interface UserInfo {
  person: Person
  emergency: Person
}

export interface UserWithHistory {
  user_id: UUID
  data: UserData
  call_history: Map<RoomId, CallHistory>
}

