import { Navigate, useLocation } from 'react-router-dom'
import { useAuthContext } from '../auth/AuthContext'
import React from 'react'

const LoginPage: React.FC = () => {
  const auth = useAuthContext()
  const location = useLocation()
  const currentLocationState = location.state || { from: { pathname: '/home' } }

  if (auth.isAuthenticated()) {
    return <Navigate to={currentLocationState?.from as string}/>
  }

  return <></>
}

export default LoginPage
