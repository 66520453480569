export function absent(obj: any | null | undefined): boolean {
  return obj === null || obj === undefined || obj === 'null'
}

export function exists(obj: any | null | undefined): boolean {
  return !absent(obj)
}

export function isNumber(key: any): boolean {
  return !isNaN(+key)
}

export function isEmptyString(value: any): boolean {
  if (!absent(value)) {
    value = value + ''
  }
  return absent(value) || value === '' || value.length === 0
}

export function isBlank(value: any): boolean {
  if (!absent(value)) {
    value = value + ''
  }
  return absent(value) || value === '' || value.length === 0 || value.trim().length === 0
}

export function isEmptyArray(arr: any[]): boolean {
  return absent(arr) || arr.length === 0
}

export function roundValue(value: number): number {
  return Math.round(value / 10) * 10
}

