import React from 'react'
import { useLocation } from 'react-router-dom'
import COLORS from '../theme/colors'

const bgColor = (path: string): React.CSSProperties => {
  if (path.includes('rooms')) {
    return { backgroundColor: COLORS.bgCall }
  } else {
    return { backgroundColor: COLORS.bgDefault }
  }
}

const style: React.CSSProperties = {
  padding: 0,
  width: '100%',
  height: '100vh',
  overflowY: 'hidden',
  position: 'relative'
}

export const WrapperPage: React.FC = ({ children }) => {
  const location = useLocation()
  return <div style={{ ...style, ...bgColor(location.pathname) }}>{children}</div>
}

export const withWrapperPage = WrappedComponent => props => {
  return (
    <WrapperPage>
      <WrappedComponent />
    </WrapperPage>
  )
}

export default WrapperPage
