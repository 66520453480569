import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAuthContext } from './AuthContext'
import { setAuth } from './authSlice'

// Listens continuously for changes in the auth context
// and updates the auth state accordingly
export const useAuthStateUpdate = () => {
  const { token } = useAuthContext()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setAuth(token))
  }, [dispatch, token])
}
