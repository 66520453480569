import React, { useEffect, useRef } from 'react'
import './Video.css'

export interface VideoProps {
  src: MediaStream | null
  muted: boolean
  circle: boolean
  width?: number | string
  height?: number | string
}

const Video: React.FC<VideoProps> = ({ src, muted, circle = false, width, height }) => {
  const refVideo = useRef(null)

  useEffect(() => {
    if (!refVideo.current) {
      return
    }

    let isMuted = muted ?? false
    let video: any = refVideo

    if (isMuted) {
      //open bug since 2017 that you cannot set muted in video element https://github.com/facebook/react/issues/10389
      video.current.defaultMuted = true
      video.current.muted = true
    }

    video.current.srcObject = src
  }, [src, muted])

  return (
    <span style={{borderRadius: 16}}>
      <video ref={refVideo} style={{ maxHeight: height ?? '90vh', minHeight: height ?? 'unset' }}
             autoPlay
             className={circle ? 'video video-circle' : 'video'}
             width={width ?? '100%'}
             muted={muted}
             playsInline // FIX iOS black screen
      />
    </span>
  )
}

export default Video
