import { createApiThunk, irisServerRequest, Method } from '.'
import { CancelRoomRequest, JoinRoomRequest, RejectRoomRequest } from './types'
import { CreateRoom, RoomInfo } from '../misc/domain/room'
import { UserData } from '../misc/domain/user'
import { UUID } from '../misc/domain'

export interface UserSessionReq {
  userId: UUID
  sessionId: UUID
}

// User requests.
export const getUserMe = createApiThunk<UUID, UserData>(
  'api/getUserMe',
  irisServerRequest(Method.GET, (id) => `/users/${id}/me`)
)

export const getUserSession = createApiThunk<UserSessionReq, UserData>(
  'api/getUserSession',
  irisServerRequest(Method.GET, (req) => `/users/${req.userId}/mine/${req.sessionId}`)
)

export const postUserRoomCreate = createApiThunk<CreateRoom, CreateRoom>(
  'api/postUserRoomCreate',
  irisServerRequest(Method.POST, (room) => `/users/${room.user_id}/room/create`)
)

export const postUserInfo = createApiThunk<UserData, UserData>(
  'api/postUserInfo',
  irisServerRequest(Method.POST, (user) => `/users/${user.id}/me`)
)

export const getJoinRoom = createApiThunk<JoinRoomRequest, RoomInfo>(
  'api/getJoinRoom',
  irisServerRequest(Method.GET, ({ userId, roomId }) => `/users/${userId}/room/${roomId}/join`)
)

export const rejectRoom = createApiThunk<RejectRoomRequest, RoomInfo>(
  'api/rejectRoom',
  irisServerRequest(Method.GET, ({ userId, roomId }) => `/users/${userId}/room/${roomId}/reject`)
)

export const cancelRoom = createApiThunk<CancelRoomRequest, RoomInfo>(
  'api/cancelRoom',
  irisServerRequest(Method.GET, ({ userId, roomId }) => `/users/${userId}/room/${roomId}/cancel`)
)
