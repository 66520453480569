import { useEffect, useState } from 'react'
import { useWebSocketContext } from './WebsocketContext'
import { MessageType, WsMessage } from '../misc/domain/ws'

export const useUsersSubscriptions = () => {
  const { connected, sendMessage } = useWebSocketContext()
  const [subscribed, setSubscribed] = useState(false)

  useEffect(() => {
    if (connected && !subscribed) {
      sendMessage({ kind: MessageType.SUBSCRIBE, channel: `/topic/users` } as WsMessage<void>)
      setSubscribed(true)
    }
    return () => {
      if (connected && subscribed) {
        sendMessage({ kind: MessageType.UNSUBSCRIBE, channel: `/topic/users` } as WsMessage<void>)
        setSubscribed(false)
      }
    }
  }, [connected, subscribed, sendMessage])
}
