import React from 'react'
import { IconButton } from 'rsuite'
import CallIcon from '../../../misc/svg/call'
import { CreateRoom, RoomType } from '../../../misc/domain/room'
import { postUserRoomCreate } from '../../../api/rest'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { envSelector } from '../../../features/environment/environmentSlice'
import { userSelector } from '../../../features/user/userSlice'

const EmergencyButton = ({ label, tel, icon, type }) => {
  const { room } = useAppSelector(userSelector)
  const { user } = useAppSelector(envSelector)
  const dispatch = useAppDispatch()

  const createRoomRequest = () => {
    if (user && !room) {
      // Room has not been requested
      let room: CreateRoom = { user_id: user.uuid, is_emergency: true, invite_users: [], room_type: type }
      dispatch(postUserRoomCreate(room))
    }
  }

  return (
    <div style={{ backgroundColor: '#1F3539', margin: '10%', padding: 20, borderRadius: 20 }}>
      <p style={{ fontSize: 20, color: 'white', fontFamily: 'Alegreya Sans' }}>{label}</p>
      <p style={{ fontSize: 23, color: '#EFABAB' }}>{tel}</p>
      <p>
        <img src={icon} alt={label} width={42}/>
        <IconButton icon={CallIcon} size="md"
                    onClick={createRoomRequest}
                    appearance={'primary'}
                    style={{ backgroundColor: '#D15656', float: 'right' }}>
        </IconButton>
      </p>
    </div>
  )
}

const Emergency: React.FC = () => {
  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#28454D' }}>
      <p style={{
        height: 120,
        paddingTop: 20,
        paddingLeft: '10%',
        paddingRight: '10%',
        textAlign: 'center',
        color: 'white',
        fontSize: 20,
        fontFamily: 'Alegreya Sans'
      }}>
        Σε περίπτωση έκτακτης ανάγκης καλέστε:
      </p>
      <EmergencyButton label="Ασθενοφόρο" tel="166" icon="/img/icons/ambulance.svg" type={RoomType.AMBULANCE}/>
      <EmergencyButton label="Π. Προστασία" tel="112" icon="/img/icons/civil_protection.svg"
                       type={RoomType.CIVIL_PROTECTION}/>
      <EmergencyButton label="Αστυνομία" tel="100" icon="/img/icons/police.svg" type={RoomType.POLICE}/>
      <EmergencyButton label="Πυροσβεστική" tel="199" icon="/img/icons/fire.svg" type={RoomType.FIRE_DEPARTMENT}/>
    </div>
  )
}

export default Emergency
