import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useAppDispatch } from '../app/hooks'
import { useAuthContext } from '../auth/AuthContext'
import { wsConnected, wsMessage } from '../features/environment/environmentSlice'
import { isEmptyString } from '../misc/utils/utils'

export interface WsContextType {
  sendMessage: (msg: any) => void,
  connected: Boolean
}

const initialValue = {
  sendMessage: () => {
  },
  connected: false
}

const WebSocketContext = createContext<WsContextType>(initialValue)
export const useWebSocketContext = () => React.useContext(WebSocketContext)


export const WebSocketProvider: React.FC = ({ children }) => {
  const auth = useAuthContext()
  const ws = useRef<WebSocket | undefined>()
  const dispatch = useAppDispatch()
  const [connected, setConnected] = useState<Boolean>(false)


  let token = auth?.token


  // Send message to websocket
  const sendMessage = (msg: any) => {
    if (ws.current) {
      ws.current.send(JSON.stringify(msg))
    }
  }

  // Try to clear status and close the websocket connection (if exists)
  const close = () => {
    if (ws.current) {
      ws.current.close(1000)
      ws.current = undefined
    }
    setConnected(false)
  }

  const NORMAL_CLOSED = 1000

  // Try to connect to web socket given a token, if a connection does not already exists
  // On close this method retries to reconnect after given interval (in millis).
  const tryConnect = useCallback((token: String, interval: number) => {
    if (ws.current) {
      close()
    } else {
      let host = window.location.host.includes('localhost') ? 'localhost:3001' : window.location.host
      let proto = window.location.host.includes('localhost') ? 'ws' : 'wss'
      ws.current = new WebSocket(`${proto}://${host}/ws?auth_token=${token}`)

      ws.current.onopen = () => {
        console.log('WS OPENED')
        setConnected(true)
        dispatch(wsConnected(true))
      }

      // We use the code 1000 to indicate a normal request close that needs to reconnect immediatelly
      // see https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#status_codes
      ws.current.onclose = (evt: CloseEvent) => {
        console.log('WS CLOSED code = ', evt.code)

        // Clear status
        if (ws.current) {
          ws.current.close()
          ws.current = undefined
        }
        setConnected(false)
        dispatch(wsConnected(false))

        if (evt.code === NORMAL_CLOSED) {
          tryConnect(token, interval)
        } else
          setTimeout(() => {
            console.log(`WS trying to reconnect after ${interval} millis`)
            tryConnect(token, interval)
          }, interval)
      }

      ws.current.onerror = (error: Event) => {
        console.error(`WS ERROR ${JSON.stringify(error)}`)
      }

      ws.current.onmessage = (evt: MessageEvent) => {
        let payload
        try {
          payload = JSON.parse(evt.data)
        } catch {
          console.error(`WS invalid JSON message: ${evt.data}`)
          return
        }

        dispatch(wsMessage(payload))
      }
    }
  }, [dispatch])

  useEffect(() => {
    let interval = 5000
    if (!isEmptyString(token)) {

      if (ws.current) {
        // We use the code 1000 to indicate a normal request close that needs to reconnect immediatelly
        // see https://developer.mozilla.org/en-US/docs/Web/API/CloseEvent#status_codes
        ws.current.onclose = (evt: CloseEvent) => {
          console.log('WS CLOSED code = ', evt.code)

          // Clear status
          if (ws.current) {
            ws.current.close()
            ws.current = undefined
          }
          setConnected(false)
          dispatch(wsConnected(false))

          if (evt.code === NORMAL_CLOSED) {
            tryConnect(token, interval)
          } else
            setTimeout(() => {
              console.log(`WS trying to reconnect after ${interval} millis`)
              tryConnect(token, interval)
            }, interval)
        }
      }

      tryConnect(token, interval)
    }

  }, [tryConnect, dispatch, token])


  return (
    <WebSocketContext.Provider value={{ sendMessage, connected }}>
      {children}
    </WebSocketContext.Provider>
  )
}
