import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { envSelector } from '../../features/environment/environmentSlice'
import { useUserSubscriptions } from '../../ws/useUserSubscriptions'
import { isMobile } from 'react-device-detect'
import { useEffect } from 'react'
import DesktopUserPage from './DesktopUserPage'
import MobileUserPage from './MobileUserPage'
import { navigatePage } from '../../misc/utils/navigation'
import { useNavigate } from 'react-router-dom'

const HomePage = () => {
  const { user } = useAppSelector(envSelector)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  useUserSubscriptions()

  useEffect(() => {
    if (!user) navigatePage(dispatch, navigate, '/home')
  }, [dispatch, navigate, user])

  if (!user) return <></>

  return isMobile ? <MobileUserPage/> : <DesktopUserPage/>
}

export default HomePage
