import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web'
import { KeycloakTokenParsed } from 'keycloak-js'
import React, { PropsWithChildren, useState } from 'react'
import keycloakConfig from '../keycloak'
import { isEmptyString } from '../misc/utils/utils'

type AuthContext = {
  token: string,
  isAuthenticated: () => boolean,
  updateToken: (token: string) => void,
  parsedToken: () => KeycloakTokenParsed | null,
  login: () => void
  logout: () => void
}

const initial: AuthContext = {
  token: '',
  isAuthenticated: () => false,
  updateToken: () => {
  },
  parsedToken: () => null,
  login: () => {
  },
  logout: () => {
  },
}

const Context = React.createContext<AuthContext>(initial)
export const useAuthContext = () => React.useContext(Context)

const AuthContextInner: React.FC<PropsWithChildren<{ token: string, updateToken: (token: string) => void }>> =
  ({
     children,
     token,
     updateToken
   }) => {

    const { initialized, keycloak } = useKeycloak()

    const isAuthenticated = () => {
      return !isEmptyString(token)
    }

    const logout = () => {
      if (initialized) {
        keycloak?.logout()
      }
      updateToken('')
    }

    const login = () => {
      if (initialized) {
        keycloak?.login()
      }
    }

    const parsedToken = () => {
      return keycloak?.tokenParsed ?? null
    }

    return (
      <Context.Provider value={{ token, login, logout, updateToken, isAuthenticated, parsedToken }}>
        {children}
      </Context.Provider>
    )
  }


export const AuthContextProvider: React.FC = ({ children }) => {
  const [token, setToken] = useState<string>('')

  const updateToken = (newToken: string) => {
    // The check avoids updating the token status
    // twice this happens because on hard reload the `tokenLogger` below runs multiple times if not changed
    if (newToken !== token) {
      console.log(`Token refresh...`)
      setToken(newToken)
    }
  }

  const eventLogger = (event: unknown, error: unknown) => {
    // console.log('onKeycloakEvent', event, error)
  }

  const tokenLogger = (tokens: any) => {
    updateToken(tokens?.token ?? '')
  }


  return (
    <ReactKeycloakProvider
      authClient={keycloakConfig}
      onEvent={eventLogger} onTokens={tokenLogger}
      initOptions={{ onLoad: 'login-required', checkLoginIframe: false }}
    >
      <AuthContextInner token={token} updateToken={updateToken}>
        {children}
      </AuthContextInner>
    </ReactKeycloakProvider>
  )
}
