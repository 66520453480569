import React, { useState } from 'react'
import { Badge, Table } from 'rsuite'
import {
  CallStatus,
  CallStatusColor,
  CallStatusTranslation,
  Room,
  RoomType,
  RoomTypeIcon,
  RoomTypeTranslation
} from '../../../misc/domain/room'
import DoingRoundIcon from '@rsuite/icons/DoingRound'
import CheckRoundIcon from '@rsuite/icons/CheckRound'
import DraftRoundIcon from '@rsuite/icons/DraftRound'
import WarningRoundIcon from '@rsuite/icons/WarningRound'
import OnlineUser from '../../../components/user/OnlineUser'
import { dateDiffMinutes } from '../../../misc/utils/date'
import { UserData } from '../../../misc/domain/user'
import { useInterval } from '../../../misc/utils/useInterval'

const tableStyle: React.CSSProperties = {
  marginRight: '5px',
  marginLeft: '5px',
  boxShadow: '0px 0px 12px rgba(2, 138, 141, 0.16)',
  borderRadius: '8px',
  backgroundColor: 'white'
}

enum DATE_TYPE {
  CREATED_DATE,
  PENDING_TIME
}

const PositionCell = ({ ...props }) => {
  return <Table.Cell {...props}>{props.rowIndex + 1}</Table.Cell>
}

const BadgeCell = ({ ...props }) => {
  const room: Room = props.rowData as Room
  if (room.created_by_interpreter) {
    return <Table.Cell {...props}><Badge content="εξερχόμενη" color="blue"/></Table.Cell>
  }
  if (room.is_emergency) {
    return <Table.Cell {...props}><Badge content="έκτακτο" color="red"/></Table.Cell>
  }
  if (dateDiffMinutes(new Date(room.created_date), new Date()) < 5 &&
    (room.status === CallStatus.PENDING || room.status === CallStatus.ERROR)) {
    return <Table.Cell {...props}><Badge content="νέα" color="violet"/></Table.Cell>
  }
  return <Table.Cell {...props}><></>
  </Table.Cell>
}

const NameCell = ({ connectedUsers, ...props }) => {
  const room: Room = props.rowData as Room
  const user: UserData = room.created_by_interpreter ? room.target : room.creator
  const connected = connectedUsers.find(u => u.id === user.id)

  return <Table.Cell {...props}>
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute', top: -10 }}>
        {user ? <OnlineUser user={user} showBadge={connected} showName/> : <></>}
      </div>
    </div>
  </Table.Cell>
}

const DateCell = ({ type, ...props }) => {
  const room: Room = props.rowData as Room
  let value = ''
  let date = new Date(room.created_date)
  if (type === DATE_TYPE.CREATED_DATE) {
    let hours = date?.getHours()
    let minutes = date?.getMinutes()
    value = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`
  } else if (type === DATE_TYPE.PENDING_TIME) {
    if (room.in_call_date) {
      let minutes = dateDiffMinutes(new Date(room.created_date), new Date(room.in_call_date))
      if (minutes < 0) minutes = 0
      value = `${minutes} λεπτά`
    } else if (room.end_date) {
      let minutes = dateDiffMinutes(new Date(room.created_date), new Date(room.end_date))
      if (minutes < 0) minutes = 0
      value = `${minutes} λεπτά`
    } else {
      let minutes = dateDiffMinutes(date, new Date())
      if (minutes < 0) minutes = 0
      value = `${minutes} λεπτά`
    }
  }

  return <Table.Cell {...props}>{value}</Table.Cell>
}

export const CallIcon = ({ status }) => {
  let icon = <></>
  switch (status) {
    case CallStatus.PENDING:
      icon = <DoingRoundIcon style={{ color: CallStatusColor[status] }} width={20} height={20}/>
      break
    case CallStatus.CLOSED:
      icon = <CheckRoundIcon style={{ color: CallStatusColor[status] }} width={20} height={20}/>
      break
    case CallStatus.IN_CALL:
      icon = <DraftRoundIcon style={{ color: CallStatusColor[status] }} width={20} height={20}/>
      break
    case CallStatus.NOT_ANSWERED:
      icon = <WarningRoundIcon style={{ color: CallStatusColor[status] }} width={20} height={20}/>
      break
    case CallStatus.REJECTED:
      icon = <WarningRoundIcon style={{ color: CallStatusColor[status] }} width={20} height={20}/>
      break
  }

  return icon
}

const StatusCell = ({ ...props }) => {
  const status: CallStatus = props.rowData.status

  return <Table.Cell {...props}>
    <CallIcon status={status}/>
    <span style={{ paddingLeft: 5 }}>{CallStatusTranslation[status] ?? ''}</span>
  </Table.Cell>
}

const ActionCell = ({ answer, ...props }) => {
  const room: Room = props.rowData as Room

  if (room.status !== CallStatus.PENDING) {
    return <Table.Cell {...props}><></>
    </Table.Cell>
  }

  let color = room.is_emergency ? '#D06662' : '#3B888C'
  return <Table.Cell {...props}>
    <>
      {!room.created_by_interpreter &&
        <span style={{ backgroundColor: color, color: 'white', cursor: 'pointer', padding: 5, borderRadius: 5 }}
              onClick={() => answer(room)}>
        <img src="/img/icons/call.svg" width="15" alt=""/>
        <span style={{ paddingLeft: 5 }}>Απάντηση</span>
      </span>}
    </>
  </Table.Cell>
}

const TypeCell = ({ ...props }) => {
  const room: Room = props.rowData as Room
  return <Table.Cell {...props}>
    {room.room_type !== RoomType.SIMPLE &&
      <img src={RoomTypeIcon[room.room_type]} width="20" height="20"
           style={{ backgroundColor: '#D06662', padding: 3, borderRadius: 50 }} alt=""/>}
    <span style={{ paddingLeft: 5 }}>{RoomTypeTranslation[room.room_type]}</span>
  </Table.Cell>
}

export interface CallsProps {
  rooms: Room[]
  connectedUsers: UserData[]
  answer: (c: Room) => void
}

const Calls: React.FC<CallsProps> = ({ rooms, connectedUsers, answer }) => {
  let [counter, setCounter] = useState(0)

  useInterval(() => {
    setCounter(counter++)
  }, 10_000)

  return (
    <Table
      style={tableStyle}
      virtualized
      wordWrap
      height={window.innerHeight - 240}
      data={rooms}>

      <Table.Column width={70} align="right" fixed>
        <Table.HeaderCell>Σειρά</Table.HeaderCell>
        <PositionCell/>
      </Table.Column>

      <Table.Column width={100} align="center" fixed>
        <Table.HeaderCell><span/></Table.HeaderCell>
        <BadgeCell/>
      </Table.Column>

      <Table.Column width={300}>
        <Table.HeaderCell>Όνομα Χρήστη</Table.HeaderCell>
        <NameCell connectedUsers={connectedUsers}/>
      </Table.Column>

      <Table.Column width={140}>
        <Table.HeaderCell>Δημιουργία</Table.HeaderCell>
        <DateCell type={DATE_TYPE.CREATED_DATE}/>
      </Table.Column>

      <Table.Column width={140}>
        <Table.HeaderCell>Αναμονή</Table.HeaderCell>
        <DateCell type={DATE_TYPE.PENDING_TIME}/>
      </Table.Column>

      <Table.Column width={200}>
        <Table.HeaderCell>Τύπος</Table.HeaderCell>
        <TypeCell/>
      </Table.Column>

      <Table.Column flexGrow={200}>
        <Table.HeaderCell>Κατάσταση</Table.HeaderCell>
        <StatusCell/>
      </Table.Column>

      <Table.Column width={150} align={'center'}>
        <Table.HeaderCell><></>
        </Table.HeaderCell>
        <ActionCell answer={answer}/>
      </Table.Column>
    </Table>
  )
}

export default Calls
