import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { userSelector } from '../../features/user/userSlice'
import { Person, UserData } from '../../misc/domain/user'
import { useEffect, useRef, useState } from 'react'
import { navigatePage } from '../../misc/utils/navigation'
import { Button, Col, Grid, Input, Row } from 'rsuite'
import AvatarEditor from 'react-avatar-editor'
import TrashIcon from '@rsuite/icons/Trash'
import { postUserInfo } from '../../api/rest'

enum Stage {
  FIRST,
  SECOND
}

const AvatarUploader = ({ image, onChange }) => {
  const editor = useRef(null)
  const [file, setFile] = useState<String | File | null | undefined>(image)

  return (
    <>
      {file && <>
        <AvatarEditor
          ref={editor}
          image={file}
          width={150}
          height={150}
          border={0}
          borderRadius={100}
          style={{ borderRadius: 15, outline: '10px solid #EEF2F3' }}
          onImageReady={() => {
            let canvas = (editor.current as any).getImageScaledToCanvas()
            let data = canvas.toDataURL('image/webp')
            onChange(data)
          }}
          onImageChange={() => {
            let canvas = (editor.current as any).getImageScaledToCanvas()
            let data = canvas.toDataURL('image/webp')
            onChange(data)
          }}
        />
        <p style={{ paddingTop: 10, color: '#3B888C', cursor: 'pointer' }}
           onClick={() => {
             setFile(null)
             onChange(null)
           }}>
          <TrashIcon/>&nbsp;Διαγραφή
        </p>
      </>}

      {!file && <>
        <img src="/img/register/add-image.svg" width="150" alt="Προσθέστε φωτογραφία"
             onClick={() => document.getElementById('avatar-input')?.click()}/>
        <input id="avatar-input" style={{ display: 'none' }} type="file"
               onChange={f => setFile(f.target.files?.item(0))}/>
      </>}
    </>
  )
}

const UserDetails = ({ user, next }) => {
  const [person, setPerson] = useState<Person>({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    address: user.address,
    kepa_id: user.kepa_id,
    image: user.image
  })

  return (
    <div style={{ padding: 30, paddingTop: 50 }}>
      <div>
        <p style={{ fontWeight: 400, fontSize: 25, fontFamily: 'Alegreya Sans' }}>Στοιχεία χρήστη</p>
        <p style={{ fontSize: 12 }}>Εισάγεται τα προσωπικά σας στοιχεία και τα στοιχεία επικοινωνίας σας</p>
      </div>
      <div style={{ paddingTop: 20, textAlign: 'center', cursor: 'pointer' }}>
        <AvatarUploader image={person.image} onChange={image => setPerson({ ...person, image })}/>
      </div>
      <div style={{ paddingTop: 20 }}>
        <Grid fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Όνομα" value={person.first_name} disabled size="lg"/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Επώνυμο" value={person.last_name} disabled size="lg"/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Κινητό τηλέφωνο*" value={person.phone} size="lg"
                     onChange={txt => {
                       let phone = person.phone = txt as string
                       setPerson({ ...person, phone })
                     }}/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="e-mail" value={person.email} disabled size="lg"/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col xs={24} sm={24} md={24}>
              <Input placeholder="Διέυθυνση*" value={person.address} size="lg"
                     onChange={txt => {
                       let address = person.address = txt as string
                       setPerson({ ...person, address })
                     }}/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Αριθμός Κ.Ε.Π.Α." value={person.kepa_id} size="lg"
                     onChange={txt => {
                       let kepa_id = person.kepa_id = txt as string
                       setPerson({ ...person, kepa_id })
                     }}/>
            </Col>
          </Row>
        </Grid>
      </div>
      <div style={{ position: 'absolute', bottom: 30, right: 30 }}>
        <Button style={{ backgroundColor: '#3B888C', color: 'white' }}
                disabled={!person.first_name || !person.last_name || !person.phone}
                onClick={() => next({ ...person })}>Επόμενο</Button>
      </div>
    </div>
  )
}

const OtherPersonDetails = ({ user, back, finish }) => {
  const [person, setPerson] = useState<Person>({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    address: user.address,
    kepa_id: user.kepa_id,
    image: user.image
  })

  return (
    <div style={{ padding: 30, paddingTop: 50 }}>
      <div>
        <p style={{ fontWeight: 400, fontSize: 25, fontFamily: 'Alegreya Sans' }}>Στοιχεία επικοινωνάς 3ου προσώπου</p>
        <p style={{ fontSize: 12 }}>
          Προαιρετικά μπορείτε να δηλώσετε τα στοιχεία επικοινωνίας ενός 3ου προσώπου.
          Σε περίπτωση έκτακτης ανάγκης, θα επικοινωνίσουμε με αυτό το άτομο.
        </p>
      </div>
      <div style={{ paddingTop: 20 }}>
        <Grid fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Όνομα" value={person.first_name} size="lg"
                     onChange={txt => {
                       let first_name = person.first_name = txt as string
                       setPerson({ ...person, first_name })
                     }}/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Επώνυμο" value={person.last_name} size="lg"
                     onChange={txt => {
                       let last_name = person.last_name = txt as string
                       setPerson({ ...person, last_name })
                     }}/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col xs={24} sm={24} md={24}>
              <Input placeholder="Κινητό τηλέφωνο" value={person.phone} size="lg"
                     onChange={txt => {
                       let phone = person.phone = txt as string
                       setPerson({ ...person, phone })
                     }}/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 20 }}>
            <Col xs={24} sm={24} md={24}>
              <Input placeholder="Διέυθυνση" value={person.address} size="lg"
                     onChange={txt => {
                       let address = person.address = txt as string
                       setPerson({ ...person, address })
                     }}/>
            </Col>
          </Row>
        </Grid>
      </div>
      <div style={{ position: 'absolute', bottom: 30, right: 30 }}>
        <Button appearance={'subtle'} style={{ color: '#3B888C' }} onClick={back}>Προηγούμενο</Button>
        <Button style={{ backgroundColor: '#3B888C', color: 'white', marginLeft: 10 }}
                onClick={() => finish({ ...person })}>Ολοκλήρωση</Button>
      </div>
    </div>
  )
}


const MobileUserPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const userState: any = useAppSelector(userSelector)
  const user: UserData = userState.user

  let [stage, setStage] = useState(Stage.FIRST)
  let [person, setPerson] = useState<Person>(Object.assign({}, user?.info?.person ?? {}))

  useEffect(() => {
    if (!user) {
      navigatePage(dispatch, navigate, '/home')
    }
  }, [dispatch, navigate, user])

  if (!user) {
    return <></>
  }

  return (
    <div>
      {stage === Stage.FIRST &&
        <UserDetails user={user.info.person} next={p => {
          setPerson(p)
          setStage(Stage.SECOND)
        }}/>}
      {stage === Stage.SECOND &&
        <OtherPersonDetails user={user.info.emergency}
                            back={() => setStage(Stage.FIRST)} finish={(emergency) => {
          const info = { person, emergency }
          dispatch(postUserInfo({ ...user, info }))
          navigatePage(dispatch, navigate, '/home')
        }}/>}
    </div>
  )
}

export default MobileUserPage
