import axios, { AxiosInstance } from 'axios'
import { isEmptyString } from '../misc/utils/utils'

export function createAxios(token?: String): AxiosInstance {
  // A defined non-empty token means that we are already authenticated
  if (!isEmptyString(token)) {
    return axios.create({
      // baseURL: baseUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  } else {
    return createDefaultAxios()
  }
}

export function createDefaultAxios(): AxiosInstance {
  return axios.create({})
}
