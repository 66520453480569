import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { UserToken } from '../../misc/domain/user'
import { WsMessage } from '../../misc/domain/ws'


export interface EnvironmentState {
  page: string,
  ws: Ws,
  user?: UserToken,
}

export interface Ws {
  connected: boolean
}

const initialState: EnvironmentState = {
  page: '',
  ws: { connected: false }
}

const environment = createSlice({
  name: 'environment',
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserToken>) {
      state.user = action.payload
    },
    // TODO: remove this.
    setPage(state, action: PayloadAction<string>) {
      state.page = action.payload
    },
    wsConnected(state, action: PayloadAction<boolean>) {
      state.ws.connected = action.payload
    }
  }
})

// WS received message action
// Note this actions can be consumed by multiple reducers and is defined here generically
// `WebsocketContext` dispatches this action
export const wsMessage = createAction<WsMessage<any>>('ws/message')

// Convenient selector for environment
export const envSelector = (state: RootState) => state.environment

// Reducer actions
export const { setPage, wsConnected, setUser } = environment.actions

// Reducer
export default environment.reducer
