import React, {useEffect, useState} from 'react'
import {userFullName} from '../../misc/utils/user'
import {ChatMessage, RoomInfo} from '../../misc/domain/room'
import {chatService} from '../../firebase/service/chat'
import InCallDesktop from './in-call-desktop/InCallDesktop'
import {useLocation, useNavigate} from 'react-router-dom'
import {isMobile} from 'react-device-detect'
import InCallMobile from './in-call-mobile/InCallMobile'
import {MediaDevicesShim, VideoRoom, VideoRoomBuilder, WebRTCShim} from 'janus-gateway-tsdx'
import {webrtcServer} from '../../app/constants'
import {navigatePage} from '../../misc/utils/navigation'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {absent, exists} from '../../misc/utils/utils'
import {userSelector} from '../../features/user/userSlice'

// Media constraints documentation here:
// https://developer.mozilla.org/en-US/docs/Web/API/MediaStreamConstraints
// https://developer.mozilla.org/en-US/docs/Web/API/MediaTrackConstraints
const DESKTOP_MEDIA_CONSTRAINTS = {
  audio: { autoGainControl: true, echoCancellation: true, noiseSuppression: true },
  video: { width: { ideal: 1152 }, height: { ideal: 1536 } },
}

const MOBILE_MEDIA_CONSTRAINTS = {
  audio: { autoGainControl: true, echoCancellation: true, noiseSuppression: true },
  video: { width: { ideal: 1280 }, height: { ideal: 720 } },
}

const MEDIA_CONSTRAINTS = isMobile ? MOBILE_MEDIA_CONSTRAINTS : DESKTOP_MEDIA_CONSTRAINTS

const VideoRoomPage: React.FC = () => {
  const { user } = useAppSelector(userSelector)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const [hasNewMessage, setHasNewMessage] = useState<boolean>(false)
  const [remoteStreams, setRemoteStreams] = useState<any>({})
  const [chatMessages, setChatMessages] = useState<ChatMessage[]>([])
  const [localStream, setLocalStream] = useState<MediaStream | null>(null)
  const [roomReq] = useState<any>(location?.state?.room ?? null as any)
  const [room] = useState<RoomInfo>(roomReq)
  const [joinedRoom, setJoinedRoom] = useState<VideoRoom | null>(null)
  const [joined, setJoined] = useState<boolean>(false)

  useEffect(() => {
    if (!roomReq) navigatePage(dispatch, navigate, '/home')
  }, [dispatch, navigate, roomReq])

  // TODO: handle unsubscribe.
  useEffect(() => {
    if (absent(room) || joined) return
    chatService.getMessagesAsync(room.id, msg => {
      setChatMessages(msg)
      if (msg.length > 0) {
        setHasNewMessage(true)
      }
    })
  }, [room, joined, hasNewMessage])

  useEffect(() => {
    if (absent(room) || exists(joinedRoom) || joined) return
    setJoined(true)

    new VideoRoomBuilder(webrtcServer(), MEDIA_CONSTRAINTS, new MediaDevicesShim(), new WebRTCShim())
      .onLocalVideo(stream => {
        console.log('Local video:', stream)
        setLocalStream(stream)
      })
      .onRemoteVideo(event => {
        console.log('Remote video:', event)
        setRemoteStreams(p => ({ ...p, [event.feedInfo.id]: event }))
      })
      .onUnpublished((feeId) => console.log('Unpublished:', feeId))
      .onLeaving((feedId) => {
        setRemoteStreams(p => {
          delete p[feedId]
          return { ...p }
        })
      })
      .join(room.id, userFullName(user?.token as any), room.user_join_id, room.pin)
      .then(room => {
        console.log(room)
        setJoinedRoom(room)
      })
  }, [room, user, joinedRoom, joined])

  if (absent(room)) return <></>

  return <>
    {/* <Prompt when={true} message={() => `Are you sure you want to leave this page?`} /> */}
    {isMobile && <InCallMobile room={room.id} user={user}
                               localStream={localStream}
                               remoteStreams={remoteStreams}
                               chatMessages={chatMessages}
                               hasNewMessage={hasNewMessage}
                               setHasNewMessages={v => setHasNewMessage(v)}
    />}
    {!isMobile && <InCallDesktop room={room.id} user={user}
                                 localStream={localStream}
                                 remoteStreams={remoteStreams}
                                 chatMessages={chatMessages}
                                 hasNewMessage={hasNewMessage}
                                 setHasNewMessages={v => setHasNewMessage(v)}
    />}
  </>
}

export default VideoRoomPage
