import React from 'react'
import './Pulse.css'

export interface PulseProps {
  waiting: number
}

const Pulse: React.FC<PulseProps> = ({ waiting }) => {
  return (
    <>
      <span className="pulse"/>
      <span className="pulse2">{waiting}</span>
    </>
  )
}

export default Pulse
