import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  cancelRoom,
  getJoinRoom,
  getUserMe,
  getUserSession,
  postUserInfo,
  postUserRoomCreate,
  rejectRoom
} from '../../api/rest'
import { RootState } from '../../app/store'
import { CallStatus, Room, RoomInfo, RoomMetrics } from '../../misc/domain/room'
import { UserData } from '../../misc/domain/user'
import { wsMessage } from '../environment/environmentSlice'
import { WsJoin, WsRoom, WsRoomCreated, WsUserSession, WsUserUpdate } from '../../misc/domain/ws'
import { UUID } from '../../misc/domain'

export interface UserState {
  user?: UserData
  roomMetrics: RoomMetrics

  room?: RoomInfo

  roomCreated?: RoomInfo | Room
  roomInfoCreated?: RoomInfo

  incoming?: Room

  sessionId?: UUID
  disconnect: boolean
}

const initialState: UserState = {
  roomMetrics: {
    calls_not_answered: 0,
    users_waiting: 0,
    connected_interpreters: 0,
  },
  disconnect: false
}

const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    closeIncomingModal(state) {
      state.incoming = undefined
    },
    setSessionId(state, action: PayloadAction<UUID>) {
      if (!state.sessionId) {
        state.sessionId = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUserRoomCreate.fulfilled, (state, { payload }) => {
        const { id, pin, user_join_id } = payload as RoomInfo
        state.roomCreated = { id, pin, user_join_id }
        state.roomInfoCreated = state.roomCreated
      })
      .addCase(postUserRoomCreate.rejected, (state, _) => {
        state.roomCreated = undefined
        state.roomInfoCreated = undefined
      })
      .addCase(cancelRoom.fulfilled, (state, _) => {
        state.roomCreated = undefined
        state.roomInfoCreated = undefined
      })
      .addCase(cancelRoom.rejected, (state, _) => {
        state.roomCreated = undefined
        state.roomInfoCreated = undefined
      })
      .addCase(getUserMe.fulfilled, (state, { payload }) => {
        state.user = payload as UserData
      })
      .addCase(getUserSession.rejected, (state, _) => {
        state.sessionId = undefined
      })
      .addCase(rejectRoom.fulfilled, (state, _) => {
        state.roomCreated = undefined
        state.roomInfoCreated = undefined
        state.incoming = undefined
      })
      .addCase(rejectRoom.rejected, (state, { payload }) => {
        state.roomCreated = undefined
        state.roomInfoCreated = undefined
        state.incoming = undefined
      })
      .addCase(getUserMe.rejected, (state, _) => {
        state.user = undefined
      })
      .addCase(postUserInfo.fulfilled, (state, { payload }) => {
        state.user = payload as UserData
      })
      .addCase(postUserInfo.rejected, (state, _) => {
      })
      .addCase(wsMessage, (state, { payload: msg }) => {
        switch (msg.payload?.type) {
          case 'WsUserSession':
            const { id } = msg.payload as WsUserSession
            if (state.sessionId && id !== state.sessionId) {
              state.disconnect = true
            }
            break
          case 'WsUserUpdate':
            const { room_metrics } = msg.payload as WsUserUpdate
            state.roomMetrics = room_metrics
            break
          case 'WsRoom':
            const { room } = msg.payload as WsRoom
            // Workaround
            if (!room.created_by_interpreter && room.status === CallStatus.PENDING) {
              state.roomCreated = room
            }
            break
          case 'WsRoomCreated':
            const { room: incoming } = msg.payload as WsRoomCreated
            state.incoming = incoming
            break
          case 'WsJoin':
            const { invitation } = msg.payload as WsJoin
            state.room = invitation
            state.incoming = undefined
            state.roomCreated = undefined
            state.roomInfoCreated = undefined
            break
          default:
            break
        }
      })
      .addCase(getJoinRoom.fulfilled, (state, { payload }) => {
        const { id, pin, user_join_id } = payload as RoomInfo
        state.room = { id, pin, user_join_id }
        state.incoming = undefined
        state.roomCreated = undefined
      })
      .addCase(getJoinRoom.rejected, (state, _) => {
        state.room = undefined
        state.incoming = undefined
        state.roomCreated = undefined
      })
  }
})

// Convenient selector for environment
export const userSelector = (state: RootState) => state.user
// Reducer actions
export const { closeIncomingModal, setSessionId } = user.actions
// Reducer
export default user.reducer
