import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { NAVIGATION } from './constants'
import { PrivateRoute } from './PrivateRoute'
import LoginPage from '../pages/LoginPage'
import WrapperPage, { withWrapperPage } from '../pages/WrapperPage'
import HomePage from '../pages/home/HomePage'
import VideoRoomPage from '../pages/video/VideoRoomPage'
import ErrorPage from '../pages/error/ErrorPage'
import UserPage from '../pages/user/UserPage'
import DisconnectPage from '../pages/disconnect/DisconnectPage'

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<PrivateRoute component={withWrapperPage(HomePage)}/>}/>
        <Route path={NAVIGATION.HOME.url} element={<PrivateRoute component={withWrapperPage(HomePage)}/>}/>
        <Route path={NAVIGATION.USER.url} element={<PrivateRoute component={withWrapperPage(UserPage)}/>}/>
        <Route path={NAVIGATION.ROOM.url} element={<PrivateRoute component={withWrapperPage(VideoRoomPage)}/>}/>
        <Route path={NAVIGATION.LOGIN.url} element={<WrapperPage><LoginPage/></WrapperPage>}/>
        <Route path={NAVIGATION.DISCONNECTED.url} element={<WrapperPage><DisconnectPage/></WrapperPage>}/>
        <Route path="*" element={<WrapperPage><ErrorPage/></WrapperPage>}/>
      </Routes>
    </Router>
  )
}


export default AppRoutes
