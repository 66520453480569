import React, { useCallback, useEffect, useState } from 'react'
import { Tag } from 'rsuite'

const Clock = () => {
  const [time, setTime] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  const refreshClock = useCallback(() => {
    setTime(time + 1)
    setMinutes(Math.floor(time / 60))
    setSeconds(time - minutes * 60)
  }, [minutes, time])

  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000)
    return () => clearInterval(timerId)
  }, [refreshClock])

  return (
    <Tag><span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></Tag>
  )
}

export default Clock
