import OnlineUsers from './OnlineUsers'
import { Col, Grid, Row } from 'rsuite'
import Calls from './Calls'
import { Room } from '../../../misc/domain/room'
import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { useInterpreterSubscriptions } from '../../../ws/useInterpreterSubscriptions'
import { getJoinRoom } from '../../../api/rest'
import { interpreterSelector, InterpreterState } from '../../../features/interpreter/interpreterSlice'
import { envSelector } from '../../../features/environment/environmentSlice'
import { navigatePage } from '../../../misc/utils/navigation'
import { useNavigate } from 'react-router-dom'
import EmergencyModal from './EmegerncyModal'
import StatusBoxes from './StatusBoxes'

const HomeInterpreterPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const state: InterpreterState = useAppSelector(interpreterSelector)
  const { user } = useAppSelector(envSelector)

  // const [selectedRoom, setSelectedRoom] = useState<null | Room>(null)
  const { room } = useAppSelector(interpreterSelector)

  // Subscribe to ws events.
  useInterpreterSubscriptions()

  const joinRoom = (room: Room) => {
    const userId = user?.uuid
    const roomId = room.id
    if (userId && roomId) {
      dispatch(getJoinRoom({ userId, roomId }))
    }
  }

  // Upon room request completion navigate to room
  useEffect(() => {
    if (room) {
      const { id } = room
      const path = `/rooms/${id}`
      navigatePage(dispatch, navigate, path, { state: { room } })
    }
  }, [room, dispatch, navigate])

  return (
    <>
      <EmergencyModal selectedRoom={r => joinRoom(r)}/>
      <Grid fluid style={{ paddingRight: 5 }}>
        <Row>
          <Col xs={4} sm={4} md={4} style={{ backgroundColor: 'white' }}>
            <OnlineUsers users={state.connectedUsers.filter(u => u.data.token.roles.includes('ROLE_IRIS_USER'))}/>
          </Col>
          <Col xs={20} sm={20} md={20} style={{ paddingLeft: 30, paddingRight: 30 }}>
            <StatusBoxes connectedInterpreters={state.roomMetrics.connected_interpreters}
                         callsNotAnswered={state.roomMetrics.calls_not_answered}
                         userWaiting={state.roomMetrics.users_waiting}/>
            <Calls rooms={state.rooms} connectedUsers={state.connectedUsers.map(u => u.data)}
                   answer={r => joinRoom(r)}/>
          </Col>
        </Row>
      </Grid>
    </>
  )
}

export default HomeInterpreterPage
