import React from 'react'
import { Avatar } from 'rsuite'
import { useAuthContext } from '../../../auth/AuthContext'
import ExitIcon from '@rsuite/icons/Exit'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { navigatePage } from '../../../misc/utils/navigation'
import { userSelector } from '../../../features/user/userSlice'

const logoStyle: React.CSSProperties = {
  paddingLeft: '65px',
  paddingRight: '65px',
  paddingTop: '20px',
  paddingBottom: '20px',
  height: 120
}

export interface OnlineUsersProps {
  interpreters: number
  pending: number
}

const StatBox = ({ label, count, img }) => {
  return (
    <div style={{ backgroundColor: '#EEF2F3', margin: 20, padding: 10, borderRadius: 20 }}>
      <p style={{ textAlign: 'center', fontFamily: 'Alegreya Sans', fontSize: 25, color: '#575757' }}>{label}</p>
      <p style={{ textAlign: 'center', fontFamily: 'Alegreya Sans', fontSize: 55, color: '#3C888C' }}>{count}</p>
      <p style={{ textAlign: 'center' }}><img src={img} alt={label}/></p>
    </div>
  )
}

const Stats: React.FC<OnlineUsersProps> = ({ interpreters, pending }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(userSelector)

  const auth = useAuthContext()

  return (
    <div style={{ height: '100vh' }}>
      <div style={logoStyle}>
        <img width={'100%'} src="/img/logo.svg" alt="Iris"/>
      </div>

      <div style={{ height: 'calc(100vh - 190px)', overflowY: 'auto' }}>
        <StatBox label="διερμηνείς" count={interpreters} img="/img/home/interpreter.svg"/>
        <StatBox label="χρήστες σε αναμονή" count={pending} img="/img/home/deaf.svg"/>
        {/*<p style={{ textAlign: 'center', margin: 20, padding: 10, borderRadius: 20, backgroundColor: '#F6F4F4' }}>*/}
        {/*  <img style={{ width: 100 }} src="/img/home/eik.png" alt="Ίδρυμα κοφών" />*/}
        {/*</p>*/}
      </div>
      <div style={{ height: 1, backgroundColor: '#ECF0F1' }}/>
      <div style={{ color: '#575757', position: 'relative', paddingTop: 16 }}>
        <span style={{ cursor: 'pointer' }} onClick={() => navigatePage(dispatch, navigate, '/user')}>
          <Avatar circle src={user?.info.person.image ?? '/img/avatar.svg'}
                  style={{ border: '1px solid white', marginLeft: 10 }}/>
          <span style={{ position: 'absolute', left: 60, top: 26, fontSize: 16 }}>εσείς</span>
        </span>
        <span onClick={() => auth.logout()}
              style={{ position: 'absolute', right: 20, top: 26, fontSize: 18, cursor: 'pointer' }}>
          <ExitIcon/>
        </span>
      </div>
    </div>
  )
}

export default Stats
