import { Button, Col, Grid, Input, Row, Steps } from 'rsuite'
import { useEffect, useRef, useState } from 'react'
import { Person, UserData } from '../../misc/domain/user'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import AvatarEditor from 'react-avatar-editor'
import { useNavigate } from 'react-router-dom'
import { postUserInfo } from '../../api/rest'
import { navigatePage } from '../../misc/utils/navigation'
import { userSelector } from '../../features/user/userSlice'
import TrashIcon from '@rsuite/icons/Trash'

enum Stage {
  FIRST,
  SECOND
}

const AvatarUploader = ({ image, onChange }) => {
  const editor = useRef(null)
  const [file, setFile] = useState<String | File | null | undefined>(image)

  return (
    <>
      {file && <>
        <AvatarEditor
          ref={editor}
          image={file}
          width={200}
          height={200}
          border={0}
          borderRadius={100}
          style={{ borderRadius: 15, outline: '10px solid #EEF2F3' }}
          onImageReady={() => {
            let canvas = (editor.current as any).getImageScaledToCanvas()
            let data = canvas.toDataURL('image/webp')
            onChange(data)
          }}
          onImageChange={() => {
            let canvas = (editor.current as any).getImageScaledToCanvas()
            let data = canvas.toDataURL('image/webp')
            onChange(data)
          }}
        />
        <p style={{ paddingTop: 10, color: '#3B888C', cursor: 'pointer' }}
           onClick={() => {
             setFile(null)
             onChange(null)
           }}>
          <TrashIcon/>&nbsp;Διαγραφή
        </p>
      </>}

      {!file && <>
        <img src="/img/register/add-image.svg" width="200" alt="Προσθέστε φωτογραφία"
             onClick={() => document.getElementById('avatar-input')?.click()}/>
        <input id="avatar-input" style={{ display: 'none' }} type="file"
               onChange={f => setFile(f.target.files?.item(0))}/>
      </>}
    </>
  )
}

const UserDetails = ({ user, next }) => {
  const [person, setPerson] = useState<Person>({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    address: user.address,
    kepa_id: user.kepa_id,
    image: user.image
  })

  return (
    <>
      <div style={{ padding: 50 }}>
        <p style={{ fontSize: 40, fontFamily: 'Alegreya Sans' }}>Στοιχεία χρήστη</p>
        <p style={{ fontSize: 23 }}>
          Εισάγεται τα προσωπικά σας στοιχεία και τα στοιχεία επικοινωνίας σας
        </p>
      </div>
      <div style={{ paddingTop: 10, textAlign: 'center', cursor: 'pointer' }}>
        <AvatarUploader image={person.image} onChange={image => setPerson({ ...person, image })}/>
      </div>
      <div style={{ paddingTop: 50, paddingLeft: 100, paddingRight: 100 }}>
        <Grid fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Όνομα" size={'lg'} value={person.first_name} disabled/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Επώνυμο" size={'lg'} value={person.last_name} disabled/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Κινητό τηλέφωνο*" size={'lg'} value={person.phone}
                     onChange={txt => {
                       let phone = person.phone = txt as string
                       setPerson({ ...person, phone })
                     }}/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="e-mail" size={'lg'} value={person.email} disabled/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col xs={24} sm={24} md={24}>
              <Input placeholder="Διέυθυνση*" size={'lg'} value={person.address}
                     onChange={txt => {
                       let address = person.address = txt as string
                       setPerson({ ...person, address })
                     }}/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Αριθμός Κ.Ε.Π.Α." size={'lg'} value={person.kepa_id}
                     onChange={txt => {
                       let kepa_id = person.kepa_id = txt as string
                       setPerson({ ...person, kepa_id })
                     }}/>
            </Col>
          </Row>
        </Grid>
      </div>
      <div style={{ position: 'absolute', bottom: 50, right: 50 }}>
        <Button style={{ backgroundColor: '#3B888C', color: 'white' }}
                disabled={!person.first_name || !person.last_name || !person.phone}
                onClick={() => next({ ...person })}>Επόμενο</Button>
      </div>
    </>
  )
}

const OtherPersonDetails = ({ user, back, finish }) => {
  const [person, setPerson] = useState<Person>({
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    address: user.address,
    kepa_id: user.kepa_id,
    image: user.image
  })

  return (
    <>
      <div style={{ padding: 50 }}>
        <p style={{ fontSize: 40, fontFamily: 'Alegreya Sans' }}>Στοιχεία επικοινωνάς 3ου προσώποου</p>
        <p style={{ fontSize: 23 }}>
          Προαιρετικά μπορείτε να δηλώσετε τα στοιχεία επικοινωνίας ενός 3ου προσώπου.
          Σε περίπτωση έκτακτης ανάγκης, θα επικοινωνίσουμε με αυτό το άτομο.
        </p>
      </div>
      <div style={{ paddingTop: 50, paddingLeft: 100, paddingRight: 100 }}>
        <Grid fluid>
          <Row>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Όνομα" size={'lg'} value={person.first_name}
                     onChange={txt => {
                       let first_name = person.first_name = txt as string
                       setPerson({ ...person, first_name })
                     }}/>
            </Col>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Επώνυμο" size={'lg'} value={person.last_name}
                     onChange={txt => {
                       let last_name = person.last_name = txt as string
                       setPerson({ ...person, last_name })
                     }}/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col xs={12} sm={12} md={12}>
              <Input placeholder="Κινητό τηλέφωνο" size={'lg'} value={person.phone}
                     onChange={txt => {
                       let phone = person.phone = txt as string
                       setPerson({ ...person, phone })
                     }}/>
            </Col>
          </Row>
          <Row style={{ paddingTop: 30 }}>
            <Col xs={24} sm={24} md={24}>
              <Input placeholder="Διέυθυνση" size={'lg'} value={person.address}
                     onChange={txt => {
                       let address = person.address = txt as string
                       setPerson({ ...person, address })
                     }}/>
            </Col>
          </Row>
        </Grid>
      </div>
      <div style={{ position: 'absolute', bottom: 50, right: 50 }}>
        <Button appearance={'subtle'} style={{ color: '#3B888C' }} onClick={back}>Προηγούμενο</Button>
        <Button style={{ backgroundColor: '#3B888C', color: 'white', marginLeft: 10 }}
                onClick={() => finish({ ...person })}>Ολοκλήρωση</Button>
      </div>
    </>
  )
}

const DesktopUserPage = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const userState: any = useAppSelector(userSelector)
  const user: UserData = userState.user

  let [stage, setStage] = useState(Stage.FIRST)
  let [person, setPerson] = useState<Person>(Object.assign({}, user?.info?.person ?? {}))

  useEffect(() => {
    if (!user) {
      navigatePage(dispatch, navigate, '/home')
    }
  }, [dispatch, navigate, user])

  if (!user) {
    return <></>
  }

  return (
    <Grid fluid>
      <Row>
        <Col xs={12} sm={12} md={12} style={{ backgroundColor: '#EEF2F3' }}>
          <div style={{ height: '100vh' }}>

            <p style={{ padding: 50 }}>
              <img src="/img/logo.svg" width="200" alt=""/>
            </p>

            <div style={{ padding: 50 }}>
              <Steps current={stage} vertical>
                <Steps.Item title="Στοιχεία χρήστη"/>
                <Steps.Item title="Στοιχεία επικοινωνάς 3ου προσώποου"/>
              </Steps>
            </div>

            <p style={{ textAlign: 'center', paddingTop: 20 }}>
              <img src="/img/register/registry.svg" alt=""/>
            </p>

          </div>
        </Col>
        <Col xs={12} sm={12} md={12} style={{ backgroundColor: 'white' }}>
          <div style={{ height: '100vh' }}>
            {stage === Stage.FIRST &&
              <UserDetails user={user.info.person} next={p => {
                setPerson(p)
                setStage(Stage.SECOND)
              }}/>}
            {stage === Stage.SECOND &&
              <OtherPersonDetails user={user.info.emergency}
                                  back={() => setStage(Stage.FIRST)} finish={(emergency) => {
                const info = { person, emergency }
                dispatch(postUserInfo({ ...user, info }))
                navigatePage(dispatch, navigate, '/home')
              }}/>}
          </div>
        </Col>
      </Row>
    </Grid>
  )
}

export default DesktopUserPage
