import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user/userSlice'
import Pulse from '../pulse/Pulse'
import { Button } from 'rsuite'
import { cancelRoom } from '../../../api/rest'
import { RoomId } from '../../../misc/domain'
import { RoomInfo } from '../../../misc/domain/room'

const BeforeCallUserMobile: React.FC = () => {
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(userSelector)
  const { roomCreated, roomInfoCreated } = useAppSelector(userSelector)

  const line = (roomCreated as any)?.line
  const room = roomInfoCreated as RoomInfo

  return <>
    <div style={{ position: 'fixed', width: '100%', height: '100%', backgroundColor: '#1F3539', zIndex: 100000, paddingTop: 50 }}>
      <div style={{ color: 'white', position: 'absolute', right: 15, top: 50 }}>
        <span style={{ position: 'absolute', right: 75, width: 110, top: 25 }}>σειρά αναμονής:</span>
        <Pulse waiting={line ?? 0}/>
      </div>
      <div style={{ textAlign: 'center', paddingTop: 150 }}>
        <img src="/img/home/eik.png" alt="Ίδρυμα κοφών"
             style={{
               border: '1px solid white',
               borderRadius: '50%',
               backgroundColor: '#F6F4F4',
               padding: 7,
               width: '8em',
               height: '8em'
             }}/>
        <p style={{ paddingTop: 10, color: 'white' }}>κλήση..</p>
      </div>
      <div style={{ position: 'absolute', bottom: 50, left: 'calc(50% - 60px)' }}>
        <Button size={'md'} appearance={'primary'}
                style={{ backgroundColor: '#D06662', width: 120 }}
                onClick={() => {
                  dispatch(cancelRoom({ userId: user?.id ?? '', roomId: room?.id as RoomId }))
                }}>
          <img src="/img/icons/call.svg" alt="Απάντηση"/>
          <span style={{ paddingLeft: 10 }}>Ακύρωση</span>
        </Button>
      </div>
    </div>
  </>
}

export default BeforeCallUserMobile
