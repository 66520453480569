import { Col, Grid, Row } from 'rsuite'
import React from 'react'

const boxStyle: React.CSSProperties = {
  height: '160px',
  background: '#FFFFFF',
  boxShadow: '0px 0px 12px rgba(2, 138, 141, 0.16)',
  borderRadius: '8px',
}

const countStyle: React.CSSProperties = {
  paddingTop: 15,
  fontSize: 80,
  color: '#3C888C',
  fontFamily: 'Alegreya Sans',
  fontStyle: 'normal',
  fontWeight: 900,
  lineHeight: '20px'
}

const BoxContent = ({ label, count, icon }) => {
  return (
    <div style={boxStyle}>
      <Grid fluid>
        <Row>
          <Col xs={16} sm={16} md={16}>
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              paddingTop: 25,
              paddingLeft: 25
            }}>
              <p style={{ fontSize: 20 }}>{label}</p>
              <p style={countStyle}>{count}</p>
            </div>
          </Col>
          <Col xs={8} sm={8} md={8}>
            <div style={{
              width: 110,
              height: 110,
              padding: 15,
              borderRadius: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: 25,
              background: '#E0ECED'
            }}>
              <img src={icon} style={{ width: '70%' }} alt={label}/>
            </div>
          </Col>
        </Row>
      </Grid>
    </div>
  )
}

export interface StatusBoxesProps {
  connectedInterpreters: number
  callsNotAnswered: number
  userWaiting: number
}

const StatusBoxes: React.FC<StatusBoxesProps> = ({ connectedInterpreters, callsNotAnswered, userWaiting }) => {
  return (
    <Grid fluid style={{ paddingTop: 30, paddingBottom: 30 }}>
      <Row>
        <Col xs={24} sm={24} md={8}>
          <BoxContent label="χρήστες σε αναμονή" count={userWaiting} icon="img/home/waits.svg"/>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <BoxContent label="διερμηνείς σε σύνδεση" count={connectedInterpreters} icon="img/home/interpreter_box.svg"/>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <BoxContent label="αναπάντητες κλήσεις" count={callsNotAnswered} icon="img/home/missed.svg"/>
        </Col>
      </Row>
    </Grid>
  )
}

export default StatusBoxes
