import React, { useEffect } from 'react'
import { Button } from 'rsuite'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { userSelector } from '../../../features/user/userSlice'
import { postUserRoomCreate } from '../../../api/rest'
import { envSelector } from '../../../features/environment/environmentSlice'
import { navigatePage } from '../../../misc/utils/navigation'
import { CreateRoom, RoomType } from '../../../misc/domain/room'

const InCallMain: React.FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { user } = useAppSelector(envSelector)
  const { room } = useAppSelector(userSelector)

  const createRoomRequest = () => {
    if (user && !room) {
      // Room has not been requested
      let room: CreateRoom = { user_id: user.uuid, is_emergency: false, invite_users: [], room_type: RoomType.SIMPLE }
      dispatch(postUserRoomCreate(room))
    }
  }

  // Upon room request completion navigate to room
  useEffect(() => {
    if (room) {
      const { id } = room
      const path = `/rooms/${id}`

      navigatePage(dispatch, navigate, path, { state: { room } })
    }
  }, [room, dispatch, navigate])

  return (
    <div style={{ width: '100%', height: '100vh', backgroundColor: '#1F3539' }}>
      <p style={{ marginTop: '10%', textAlign: 'center', color: 'white', fontSize: 30, fontFamily: 'Alegreya Sans' }}>
        Πώς μπορούμε να σας εξυπηρετήσουμε;
      </p>
      <div style={{
        backgroundColor: '#28454D',
        borderRadius: 20,
        marginTop: 50,
        marginRight: '10%',
        marginLeft: '10%',
        height: '50%'
      }}>
        <div style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <img src="/img/home/eik.png" height="170" width="170" alt="Ίδρυμα κοφών"
               style={{ border: '1px solid white', borderRadius: '50%', backgroundColor: '#F6F4F4', padding: 20 }}/>
        </div>
      </div>
      <div style={{ marginTop: 100, textAlign: 'center' }}>
        <Button size={'lg'} appearance={'primary'}
                style={{ backgroundColor: '#3B888C' }}
                onClick={createRoomRequest}>
          <img src="/img/icons/call.svg" alt="Απάντηση"/>
          <span style={{ paddingLeft: 10 }}>Καλέστε μας</span>
        </Button>
      </div>
    </div>
  )
}

export default InCallMain


