import ReactDOM from 'react-dom'
import React from 'react'
import reportWebVitals from './reportWebVitals'
import { App } from './app/App'
import { AuthContextProvider } from './auth/AuthContext'
import { Provider as StoreProvider } from 'react-redux'
import store from './app/store'
import { WebSocketProvider } from './ws/WebsocketContext'

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <StoreProvider store={store}>
        <WebSocketProvider>
          <App/>
        </WebSocketProvider>
      </StoreProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
